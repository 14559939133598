import styled from "styled-components";
import { useState, useEffect } from "react";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { ruminatiColors } from "../../utilities/colors";
import Screen from "../screen";
import Heading from "../../components/heading";
import { BaseText, BodyText } from "../../components/styled_text";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import MainButton from "../../components/buttons/main_button";
import { useDatabaseStore } from "../../state/database_store";
import { AllocatedSummary, Report } from "../../models/property_models";
import {
    getPropertyFromReport,
    getReport
} from "../../utilities/functions";
import { useAuthStore } from "../../state/auth_store";
import { SubscriptionType } from "../../models/subscription_model";
import { Column, Row } from "../../components/styled_layout";
import PropertyHeader from "../../components/headers/property_header";
import OutlineButton from "../../components/buttons/outline_button";
import Icon from "../../components/icon";
import { getEnterpriseTypeTag } from "../../components/enterprise_type_tag";
import Tabs from "../../components/tabs";
import ReportSummaryTab from "./overview_tab";
import ReportDetailsTab from "./detailed_tab";
import PrintPageLayout from "../../components/print/report-page-layout";
import { HelpDrawerAndTab, HelpDrawerContentWrapper, HelpDrawerExplanatoryContentContainer, HelpDrawerExplanatoryContentText, HelpDrawerExplanatoryContentTitle, HelpDrawerVideoContainer } from "@/components/HelpDrawer";
import { Accordion, AccordionItem, AccordionItemContentText } from "@/components/Accordion";

/**
 * A screen showing the finished report
 * @returns the Report Screen component
 */
export default function ReportScreen() {
    const navigate = useNavigate();
    const [isInPrint, setIsInPrint] = useState<boolean>(false);
    const [isInDownload, setIsInDownload] = useState<boolean>(false);
    const { reportId } = useParams();

    const [selectedTab, setSelectedTab] = useState(0)
    const [shouldChartsAnimate, setShouldChartsAnimate] = useState(true)

    const databaseStore = useDatabaseStore();
    const report: Report | undefined = databaseStore.reports?.find(
        (r) => r.id === reportId
    );
    const isPrime = useAuthStore().user?.subscription?.type === SubscriptionType.PRIME;
    const property = getPropertyFromReport(databaseStore.properties, getReport(databaseStore.reports, reportId));
    const propertyName = property?.name ?? "Property";

    useEffect(() => {
        if (isInPrint) {
            window.print();
            setIsInPrint(false);
        } else if (isInDownload) {
            const reportElement = document.getElementById('reportInnerContent');

            if (!reportElement) {
                console.error('ReportPrintLayout element not found');
                setIsInDownload(false)
                return;
            }
    
            html2canvas(reportElement, { scale: 2 }).then(canvas => {
                const contentDataURL = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'p',
                    unit: 'mm',
                    format: 'a4'
                });
                const width = pdf.internal.pageSize.getWidth() - 20;
                const widthRatio = width / canvas.width
                pdf.addImage(contentDataURL, 'PNG', 10, 10, width, canvas.height * widthRatio, undefined, 'MEDIUM');
                pdf.save(`${propertyName} ${report && report.financialYear} Financial Year Report.pdf`);
                setIsInDownload(false)
            });
        }
    }, [isInPrint, isInDownload, propertyName, report]);

    if (report === undefined) return <Navigate to={`/summary/${reportId}`} />;

    const results = report.results;
    if (!results || !property) return undefined

    function getReportContentHeader(hideEnterpriseTag?: boolean) {
        return (
            <Column style={{ 
                width: '100%',
                alignItems: 'flex-start',
                marginTop: '30px',
                marginBottom: '20px'
            }}
            className={'reportHeader'}>
                <Heading level={2}>
                    {`Emissions Report for ${report && report.financialYear} Financial Year`}
                </Heading>
                {property ? (
                    <Row style={{marginTop: '10px', gap: '10px'}}>
                        <BodyText style={{fontSize: '17px'}}>{property.name}</BodyText>
                        {hideEnterpriseTag ?? getEnterpriseTypeTag(property, 'small', '0px')}
                    </Row>
                ) : undefined}
            </Column>
        )
    }

    function getTabContent () {
        if (!results || !property) return <></>
        if (selectedTab === 0) {
            return (
              <ReportSummaryTab 
              property={property}
              resultsSummary={results}
              isPrime={isPrime}
              isInPrint={isInPrint}
              animateCharts={
                (isInPrint || isInDownload) ? false : shouldChartsAnimate}
            />)
        }
        return (
          <ReportDetailsTab property={property}
          resultsSummary={results}
          allocatedProductSummary={report?.resultsByProduct as AllocatedSummary}
          grains={report?.grainInformation ?? report?.grainInformationPostV3_3}
          isPrime={isPrime}
          isInPrint={isInPrint}
        />)
    }

    if (isInPrint || isInDownload) {
        return (
            <ReportInnerContent id="reportInnerContent">
                <PrintPageLayout
                    content={[
                        getReportContentHeader(true),
                        getTabContent()
                    ]}
                />                
            </ReportInnerContent>

        );
    }

    return (
        <Screen pageTitle="Report Results">
            <PropertyHeader property={property} />
            {results && (
                <ReportInnerContent id="reportInnerContent">
                    {getReportContentHeader()}
                    <Row
                        data-html2canvas-ignore={true}
                        style={{
                            width: '100%',
                            justifyContent: 'space-between',
                            marginTop: '10px',
                        }}>
                        <Column>
                            <Row>
                                <Column style={{paddingRight: '12px'}}>
                                    <OutlineButton
                                    height="40px"
                                    onClick={() => setIsInDownload(true)}
                                    children={
                                    <Row style={{padding: '0px 20px', gap: '10px'}}>
                                        <Icon icon="download-file" />
                                        Download Report
                                    </Row>
                                    }
                                    />
                                </Column>
                                <Column>
                                    <OutlineButton
                                        height="40px"
                                        onClick={() => setIsInPrint(true)}
                                        children={
                                        <Row style={{padding: '0px 20px', gap: '10px'}}>
                                            <Icon icon="print" />
                                            Print Report
                                        </Row>}
                                    />
                                </Column>
                            </Row>
                        </Column>
                        <Column>
                            <Row>
                                <Column style={{paddingRight: '8px'}}>
                                    <BodyText style={{ alignSelf: "Center", marginRight: '8px' }}>Want to view your input data?</BodyText>
                                </Column>
                                <Column>
                                    <MainButton colorScheme="orange" size="small"
                                        onClick={() => {
                                            navigate(`/summary/${report.id}`)
                                        }}>
                                        View/Manage Input Data
                                    </MainButton>                                
                                </Column>
                            </Row>
                        </Column>
                    </Row>

                    <Row 
                    data-html2canvas-ignore={true}
                    style={{
                        marginTop: '20px',
                        justifyContent: 'flex-start',
                        width: '100%'
                    }}>
                        <Tabs
                            selected={selectedTab}
                            tabs={[
                                {
                                    text: 'Summary'
                                },
                                {
                                    text: 'Detailed breakdown'
                                }
                            ]}
                            onTabChange={(index) => {
                                setSelectedTab(index)
                                setShouldChartsAnimate(false)
                            }}
                            layoutType={'Line'}
                        />
                    </Row>
                    
                    {getTabContent()}
                </ReportInnerContent>
            )}

            <HelpDrawerAndTab
                renderContent={() => {
                    return (
                        <HelpDrawerContentWrapper>
                            <HelpDrawerExplanatoryContentContainer>
                                <HelpDrawerExplanatoryContentTitle>Your Emissions Report</HelpDrawerExplanatoryContentTitle>
                                <HelpDrawerExplanatoryContentText>
                                    Your emissions report shows you a breakdown of your total emissions, sequestration and net emissions on your property. It breaks down the GHGs that make up your scope 1 emissions, as well as the percentage of scope 1,2 and 3 of your total.
                                </HelpDrawerExplanatoryContentText>
                                <HelpDrawerExplanatoryContentText>
                                    <strong>Ruminati Tip:</strong> If you want to see your emissions details by source and by enterprise, click on the “Detailed Breakdown” tab at the top of the page.
                                </HelpDrawerExplanatoryContentText>
                            </HelpDrawerExplanatoryContentContainer>
                            <HelpDrawerVideoContainer>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/33CvD8Bjnkg?si=nysNcGQDc8h5W3Wj&loop=1&rel=0" title="Ruminati Help" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </HelpDrawerVideoContainer>
                            <Accordion>
                                <AccordionItem
                                    renderTitle="What are Scope 1, 2 and 3 Emissions?"
                                    renderContent={() => (
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                                            <AccordionItemContentText>
                                                <strong>Scope 1 (Build - direct):</strong> Emissions from the sources owned or controlled by the enterprise, e.g. methane from livestock.
                                            </AccordionItemContentText>
                                            <AccordionItemContentText>
                                                <strong>Scope 2 (Burn - indirect):</strong> Energy emissions from the generation of purchased or acquired electricity, e.g. electricity used to power farm buildings or irrigate crops.
                                            </AccordionItemContentText>
                                            <AccordionItemContentText>
                                                <strong>Scope 3 (Buy - supply chain):</strong> Indirect pre-farm emissions, e.g. transport emissions from livestock before purchase.
                                            </AccordionItemContentText>
                                        </div>
                                    )}
                                />
                                <AccordionItem
                                    renderTitle="Can I produce a tangible report?"
                                    renderContent="You can download and/or print your emissions report by clicking on the ‘Download Report’ or ‘Print Report’ buttons at the top of the page."
                                />
                            </Accordion>
                        </HelpDrawerContentWrapper>
                    )
                }}
            />
        </Screen>
    );
}

export const ValueText = styled(BaseText)`
    color: ${ruminatiColors.green_3};
`;

const ReportInnerContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 936px;
    margin-bottom: 80px;
`;

export const ReportInnerSection = styled.div.attrs({
    className: "report-inner-section",
})`
    margin: 32px 0 0 0;
    border: 1px solid ${ruminatiColors.green_3_30};
    border-radius: 8px;
    padding: 16px 24px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 1;

    > * {
        width: calc(50% - 12px);
        display: flex;
        justify-content: center;

        &:nth-child(even) {
            margin-left: 12px;
        }
        &:nth-child(odd) {
            margin-right: 12px;
        }
    }
`;
