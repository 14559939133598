import { useState } from "react";

import { FormData } from "../../models/form/form";
import { DropdownFormField, NumberFormField } from "../../models/form/form_fields/basic_form_fields";
import { FertiliserUse } from "@/models/report";
import { fertiliserOptions } from "@/models/form_chemical_lookup";
import { FieldSize } from "@/models/form/form_fields/form_field";
import ArrayForm from "@/models/form/array_form";

const fertilisersFormOptions = fertiliserOptions.map(fo => {
  return {
      "value": fo.id,
      "name": fo.name
  }
})

const calculatorForm = (): FormData<FertiliserUse> => ({
    fields: {
      id: new DropdownFormField({
        required: true,
        label: 'Fertiliser Type',
        placeholder: "Fertiliser Type",
        options: fertilisersFormOptions,
        size: FieldSize.TwoThirds,
        useSuggestion: true
      }),
      rateApplied: new NumberFormField({
          required: true,
          label: "Rate applied",
          unit: "kg/ha",
          size: FieldSize.Third,
      })
    },
});
export const FertiliserUserPopupId = 'FertiliserUseId'

export default function FertiliserUsePopup({
    onSubmit,
    metadata
}: {
    onSubmit: (val: FertiliserUse[]) => void;
    metadata: {
        fertilisers: FertiliserUse[] | undefined
    }
}) {
    const formData = calculatorForm()

    const [formValues,] = useState<Partial<FertiliserUse>[]>(
        metadata.fertilisers && metadata.fertilisers.length > 0 ? metadata.fertilisers : [{}]
    );

    return (
        <div style={{ padding: "20px", width: '750px' }}>
            <ArrayForm
                data={formData as FormData<any, any>}
                initialValue={formValues}
                onChange={async () => undefined}
                onSubmit={async (values) => {
                    onSubmit(values as FertiliserUse[])
                }}
                sectionName={"Fertiliser"}
                hideSubmitButton={false}
                useRowStyle={true}
                submitText="Submit"
            />
        </div>
    );
}
