import styled from "styled-components";
import {
    Navigate,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import { isValidElement, useEffect, useMemo, useState } from "react";
import deepEqual from 'fast-deep-equal';
import ReactGA from "react-ga4";
import dayjs from "dayjs";
import { useRollbar } from "@rollbar/react";

import Screen from "../screen";
import Heading from "../../components/heading";
import { Form, FormState } from "../../models/form/form";
import ArrayForm from "../../models/form/array_form";

import { Property, PropertyType, Report } from "../../models/property_models";
import { useDatabaseStore } from "../../state/database_store";
import { getPropertyFromReport, getReport } from "../../utilities/functions";
import { ReportTrees } from "../../models/report";
import { patchReport } from "../../services/property_service";
import { usePopupStore } from "../../state/popup_store";
import { ParagraphText } from "../../components/styled_text";
import { SubscriptionType } from "../../models/subscription_model";
import { useAuthStore } from "../../state/auth_store";
import { getEnvFromUrl } from "../../utilities/env";
import { CountryCode } from "../../utilities/countries";
import { Row } from "../../components/styled_layout";
import PropertyHeader from "../../components/headers/property_header";
import { ruminatiColors } from "../../utilities/colors";
import TextButton from "../../components/buttons/text_button";
import { rations, feedlotCohorts, feedlotProduction } from "./forms/feedlot";
import { pasture, consumables, trees, supplementaryFeed, cattlePurchases, sheepPurchases, allSupplementaryFeedForms } from "./forms/shared";
import { HelpDrawerContent, ReportFormPage, ReportPageMultipleForms } from "../../models/form/form_page";
import { ReportFormPageId } from "../../utilities/forms";
import { energySources, methane, refrigerants, purchasedGoods, processorProduction, waterUse, effluentManagement } from "./forms/processor";
import { cattleClassesAus, cattleMiscAus, sheepClassesAus, sheepMiscAus, livestock } from "./forms/producer_aus";
import { cattleNZ, sheepNZ } from "./forms/producer_nz";
import { grains, grainsPost3_3, production } from "./forms/producer_shared";
import FormSteps from "./form_steps";
import MultiFormPage from "@/models/form/multiform_page";
// import LivestockSeasonalMonthlySwitch from "@/components/form_pages/LivestockSeasonalMonthly";
import { completeDairyFormSet } from "./forms/dairy";
import { HelpDrawerAndTab, HelpDrawerContentWrapper, HelpDrawerExplanatoryContentContainer, HelpDrawerExplanatoryContentText, HelpDrawerExplanatoryContentTitle, HelpDrawerVideoContainer } from "@/components/HelpDrawer";
import { Accordion, AccordionItem, AccordionItemContentText, AccordionItemTitle } from "@/components/Accordion";
import { openNotificationError } from "@/utilities/notification-defaults";
import { convertUnknownErrortoStringable } from "@/utilities/errors";

/**
 *
 * @param property
 * @param goToForm: navigates the user to a particular form
 * @param report
 * @param existingTrees
 * @returns the report form pages for the property
 */
export function generatePages(options: {
    property: Property,
    goToForm: (formId: string) => void,
    report?: Report,
    existingTrees?: ReportTrees[],
    useSeasons: boolean
}): (ReportFormPage<any> | ReportPageMultipleForms)[] {
    const { report, property, goToForm, existingTrees, useSeasons} = options
    if (report === undefined) return []

    const isNzProperty = property.country === CountryCode.NewZealand;
    const isFurtherProcessor = property.propertyType === PropertyType.FurtherProcessor;
    if (property.propertyType === PropertyType.Farm) {
        return [
            ...(property.cattleFarm || property.sheepFarm ? [pasture(property.country, report.algorithmVersion)] : []),
            consumables(property.country),
            ...((!isNzProperty && (property.cattleFarm || property.sheepFarm) && report.algorithmVersion === 2) ? [livestock(property.cattleFarm, property.sheepFarm)] : []),
            ...(isNzProperty && property.cattleFarm ? [cattleNZ] : []),
            ...((!isNzProperty && property.cattleFarm && report.algorithmVersion > 2) ? [cattleClassesAus(report.financialYear, useSeasons), cattleMiscAus(report.financialYear)] : []),
            ...(property.cattleFarm ? [cattlePurchases({
                isNzProperty,
                propertyType: property.propertyType
            })] : []),
            ...(isNzProperty && property.sheepFarm ? [sheepNZ] : []),
            ...((!isNzProperty && property.sheepFarm && report.algorithmVersion > 2) ? [sheepClassesAus(report.financialYear, useSeasons), sheepMiscAus(report.financialYear)] : []),
            ...(property.sheepFarm ? [sheepPurchases(isFurtherProcessor)] : []),
            ...((property.cattleFarm || property.sheepFarm) ?
                [report.algorithmVersion >= 3.2 ? allSupplementaryFeedForms() : supplementaryFeed()] 
            : []),
            ...(property.grainFarm && report.algorithmVersion < 3.4 ? [grains] : []),
            ...(property.grainFarm && report.algorithmVersion > 3.3 ? [grainsPost3_3()] : []),
            trees(property.region, property.geom, existingTrees ?? [], report.id, property.country),
            production(property.cattleFarm, property.sheepFarm, property.grainFarm, report),
        ];
    } else if (property.propertyType === PropertyType.Feedlot) {
        return [
            rations,
            feedlotCohorts(report, () => goToForm(ReportFormPageId.Rations)),
            cattlePurchases({
                isNzProperty,
                propertyType: property.propertyType
            }),
            supplementaryFeed("Livestock Feed"),
            consumables(property.country),
            pasture(property.country, report.algorithmVersion),
            trees(property.region, property.geom, existingTrees ?? [], report?.id, property.country),
            ...(report.algorithmVersion > 3.4 ? [feedlotProduction] : []),
        ];
    } else if (property.propertyType === PropertyType.Processor ||
        property.propertyType === PropertyType.FurtherProcessor) {
        return [
            energySources,
            ...(!isFurtherProcessor ? [methane()] : []),
            refrigerants(report),
            ...((report
                && 'algorithmVersion' in report
                && report?.algorithmVersion > 2
                && isFurtherProcessor
            ) ? [purchasedGoods()] : []),
            ...(property.beefProcessor ? [cattlePurchases({
                isNzProperty,
                propertyType: property.propertyType
            })] : []),
            ...(property.sheepProcessor ? [sheepPurchases(isFurtherProcessor)] : []),
            effluentManagement(isFurtherProcessor),
            waterUse(),
            processorProduction(property.beefProcessor, property.sheepProcessor, isFurtherProcessor),
        ];
    } else if (property.propertyType === PropertyType.Dairy) {
        return completeDairyFormSet(property, report, existingTrees)
    }

    throw new Error("[form_screen.tsx] Unimplemented property type");
}

function sendGoogleAnalyticsEvent(startTime: number, formTitle: string, updateType: "Initial" | "Update") {
    const timeTaken = dayjs(Date.now()).diff(startTime, 'second')
    if (getEnvFromUrl() === 'production') {
        ReactGA.event({
            category: "forms",
            action: `${formTitle} | ${updateType}`,
            value: timeTaken,
            nonInteraction: true,
        });
    }
}

type VariousFormStates = FormState | FormState[] | Record<ReportFormPageId, (FormState | FormState[])>

/**
 * Creates a form screen based on the form id given in the route. Uses data
 * in pages.tsx to build screen
 * @returns the Form Screen component
 */
export default function ReportFormScreen() {
    const { reportId, formId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const rollbar = useRollbar()

    const user = useAuthStore((state) => state.user);

    const [dirty, setDirty] = useState<boolean>(false);
    const [showMissingFields, setShowMissingFields] = useState(false)

    const popupStore = usePopupStore();
    const databaseStore = useDatabaseStore();

    const report = getReport(databaseStore.reports, reportId);

    const property = getPropertyFromReport(databaseStore.properties, report);

    const pages = useMemo(() => {
        const allPropertyTrees: ReportTrees[] = property && property.id in databaseStore.trees ? databaseStore.trees[property.id] : []
        const nonReportTrees = allPropertyTrees.filter(t => t.reportId !== reportId)
        const out = property
            ? generatePages({
                property,
                goToForm: (formId: string) => {
                    setShowMissingFields(false)
                    navigate(`/form/${reportId}/${formId}`);
                },
                report,
                existingTrees: nonReportTrees,
                useSeasons: true
            }) : []
        return out;
    }, [property, databaseStore.trees, report, reportId, navigate]);

    const pageIndex: number = pages.findIndex((p) => p.id === formId);
    const page = pageIndex !== -1 ? pages[pageIndex] : undefined;
    const nextPage = pages[(pageIndex + 1) % pages.length];

    // Once the user changes page, reset dirty state
    useEffect(() => {
        setDirty(false)
    }, [location]);

    function getExistingOrEmptyDataFromReport (reportPage: ReportFormPage<any>): FormState | FormState[] {
        if (report === undefined) return {}
        const fallback = reportPage.repeatableConfig ? [] : {}
        const existingData = report[reportPage.id as keyof Report]
        return JSON.parse(JSON.stringify(existingData ?? fallback))
    }

    function getExistingData (): VariousFormStates {
        if (page === undefined || report === undefined) return {};
        if ('formComponents' in page && page.formComponents) {
            const out: Partial<Record<ReportFormPageId, (FormState | FormState[])>> = {}
            page.formComponents.forEach(fc => {
                out[fc.id as ReportFormPageId] = getExistingOrEmptyDataFromReport(fc)
            })
            return JSON.parse(JSON.stringify(out))
        }
        return JSON.parse(JSON.stringify(getExistingOrEmptyDataFromReport(page as ReportFormPage<any>)))
    }

    const loadedData = useMemo(getExistingData, [report, page])

    function guardedNavigate (route: string) {
        if (dirty) {
            popupStore.addPopup("confirmation", undefined, {
                title: "Leave page?",
                text: "You haven't submitted the data in this form, if you leave the page it will be lost.",
                buttonText: "Leave without saving changes",
                onConfirm: () => {
                    setShowMissingFields(false)
                    navigate(route);
                },
            });
        } else {
            setShowMissingFields(false)
            navigate(route);
        }
    }

    // Temp subscription guard fix for production report page.
    if (formId === ReportFormPageId.Production && user?.subscription.type !== SubscriptionType.PRIME) {
        return <Navigate to={`/summary/${reportId}`} />;
    }

    // If no report or no property, invalid report so redirect back to dashboard.
    if (report === undefined || property === undefined || user === undefined) {
        return <Navigate to="/dashboard" />;
    }

    // If id was not available go to the first page
    if (page === undefined) {
        return <Navigate to={`/form/${report.id}/${pages[0]!.id}`} />;
    }

    const startTime = Date.now();

    const onSubmit = async (value: any, useRawValue?: boolean) => {
        if (reportId !== undefined) {
            try {
                let hadInitialData = false
                if (Array.isArray(loadedData) && loadedData.length > 0) hadInitialData = true
                else if (typeof loadedData === 'object' && Object.keys(loadedData).length > 0) hadInitialData = true

                sendGoogleAnalyticsEvent(startTime, page.title, hadInitialData === false ? 'Initial' : 'Update')
                
                const newData = useRawValue ? value : {
                    [page.id]: value,
                }

                const newReport = await patchReport(reportId, newData);
                if (newReport) {
                    databaseStore.updateReport(newReport);
                    if (page.id === ReportFormPageId.Trees) databaseStore.removeTreesForPropertyId(property.id)
                    if (newReport.resultsReadyForGeneration) {
                        setShowMissingFields(false)
                        navigate(`/summary/${reportId}`);
                    } else {
                        setShowMissingFields(false)
                        navigate(`/form/${reportId}/${nextPage?.id}`);
                    }
                } else {
                    // N.B. not sure this can ever really happen, but technically Report.fromJSON can return null (from patchReport)
                    // same error as an error patching
                    openNotificationError({
                        heading: 'Error Submitting Report',
                        message: 'Looks like something went wrong. Please try again later or let us know if you continue to have problems.',
                    })
                    rollbar.error('Error Submitting Report', {
                        errorDetails: 'Report.fromJSON returned null when patching report.',
                    })
                }
            } catch (e) {
                const isDataError = e instanceof Error && e.message === "INVALID_DATA"
                if (isDataError) {
                    openNotificationError({
                        heading: 'Error Submitting Report',
                        message: 'It looks like something went wrong submitting your data, please see description below. Let us know if you continue to have problems.',
                        cause: e instanceof Error ? e.cause as string : undefined
                    })
                } else {
                    openNotificationError({
                        heading: 'Error Submitting Report',
                        message: 'Looks like something went wrong. Please try again later or let us know if you continue to have problems.',
                    })
                }

                rollbar.error('Error Submitting Report', {
                    errorDetails: convertUnknownErrortoStringable(e),
                })
            }
        }
    };

    async function onChange () {
        setDirty(true)
    };

    async function onArrayChange (formPage: ReportFormPage<any, any>, formState: FormState[]) {
        const newState = formPage.formData.transformer ? formState.map(s => formPage.formData.transformer?.out(s)) : formState;

        // Fields to ignore when checking whether the form has changed
        const ignoreFields = ['completionDate'];
        const cleanState = (state: any) => {
            return (Array.isArray(state) ? state : [state]).map(section => {
                if (section === undefined) return section
                // Copy so we don't affect original object
                const sectionCopy = JSON.parse(JSON.stringify(section));
                // Delete each of the ignore fields from the comparison
                for (const field of ignoreFields) {
                    delete sectionCopy[field];
                }
                return sectionCopy;
            });
        };
        const initalData = (report as any)[formPage.id];
        const noChanges = deepEqual(cleanState(initalData), cleanState(newState));

        setDirty(!noChanges)
    }

    function renderFormPage (formPage: ReportFormPage<any>): JSX.Element {
        if (formPage.repeatableConfig)  {
            return <ArrayForm
            data={formPage.formData}
            initialValue={loadedData as FormState[]}
            onChange={(formState) => onArrayChange(formPage, formState)}
            sectionName={formPage.repeatableConfig?.repeatableSectionName}
            sectionNameCallback={formPage.repeatableConfig.repeatableSectionCallback}
            onSubmit={onSubmit}
            fieldSize="small"
            submitText="Save and Continue"
            minimumRequired={formPage.repeatableConfig.minimumRequired ?? 0}
            maximumAllowed={formPage.repeatableConfig.maximumAllowed ?? undefined}
            hideSubmitButton={formPage.hideActionButtons}
            hideAddButton={formPage.hideActionButtons}
            showMissingFields={showMissingFields}
            setShowMissingFields={setShowMissingFields}
            />
        }
        return <Form
            id={formId!}
            data={formPage.formData}
            initialValue={loadedData}
            onSubmit={onSubmit}
            onChange={onChange}
            hideSubmitButton={formPage.hideActionButtons}
            submitText="Save and Continue"
            fieldSize="small"
            buttonSize="medium"
            showMissingFields={showMissingFields}
            setShowMissingFields={setShowMissingFields}
        />
    }

    function renderMultiFormPage (multiFormpage: ReportPageMultipleForms): JSX.Element {
        if (!report) return <></>
        return <MultiFormPage
            report={report}
            reportPage={multiFormpage}
            initialData={loadedData as Record<string, (Partial<FormState> | Partial<FormState>[])>}
            onSubmit={onSubmit}
            onStandardFormChange={onChange}
            onArrayFormChange={onArrayChange}
            showMissingFields={showMissingFields}
            setShowMissingFields={setShowMissingFields}
        />
    }


    return (
        <Screen pageTitle={"Report - " + page.title}>
            <PropertyHeader property={property} />
            <FormPageWrapper>
                <FormSteps
                    pages={pages}
                    pageId={page.id}
                    report={report}
                    user={user}
                    navigate={(targetStepId)=> {
                        guardedNavigate(`/form/${reportId}/${targetStepId}`)
                    }}
                />
                <FormPageContainer>
                    <Row style={{ marginBottom: page.description ? '24px' : '36px' }}>
                        <Heading level={2}>{page.title}</Heading>
                    </Row>
                    {/* <div style={{ 
                        display: 'grid', 
                        marginBottom: page.description || page.descriptionActions ? '24px' : '36px',
                        width: '100%',
                        alignItems: 'center'
                     }}>
                        <div style={{
                            gridColumnStart: 1,
                            gridRowStart: 1,
                            justifySelf: 'center'
                        }}>
                            <Heading level={2}>{page.title}</Heading>
                        </div>
                        <div style={{
                          gridColumnStart: 1,
                          gridRowStart: 1,
                          justifySelf: 'right'
                        }}>
                        {isLivestockPage && 
                            <LivestockSeasonalMonthlySwitch 
                            useSeasons={useSeasons} 
                            onChange={(val) => setUseSeasons(val)}
                            />
                        }
                        </div>
                    </div> */}

                    {page.description && page.description.length > 0 ? <Row style={{ marginBottom: '24px' }}>
                        <ParagraphText
                            style={{
                                fontSize: "16px",
                                lineHeight: "1.5em",
                            }}
                        >
                            {page.description}
                        </ParagraphText>
                    </Row> : undefined}
                    
                    {
                        'formComponents' in page && page.formComponents 
                            ? renderMultiFormPage(page) 
                            : renderFormPage(page as ReportFormPage<any, any>)
                    }

                    <Row style={{ marginTop: '24px' }}>
                        <TextButton
                            bottomBorder={true}
                            fontSize={'14px'}
                            textColor={ruminatiColors.green_3}
                            hoverTextColor={ruminatiColors.dark_green}
                            onClick={() => {
                                guardedNavigate(`/farm/${property.id}`)
                            }}
                        >
                            Return to {property.name} dashboard
                        </TextButton>
                    </Row>

                    {/**
                     * Show the form page's image at the bottom of the page
                     */}
                    {page.image && (
                        <img
                            src={`/images/${page.image}`}
                            alt={page.title}
                            style={{ width: "80%", maxWidth: "700px" }}
                        />
                    )}
                </FormPageContainer>

                {page.helpDrawerContent && (
                    <HelpDrawerAndTab
                        renderContent={() => {
                            if (isValidElement(page.helpDrawerContent)) {
                                return page.helpDrawerContent;
                            } else {
                                const { title, content, videoLink, accordionItems } = page.helpDrawerContent as HelpDrawerContent
                                return (
                                    <HelpDrawerContentWrapper>
                                        <HelpDrawerExplanatoryContentContainer>
                                            <HelpDrawerExplanatoryContentTitle>
                                                {isValidElement(title)
                                                    ? title
                                                    : title as string}
                                            </HelpDrawerExplanatoryContentTitle>
                                            <HelpDrawerExplanatoryContentText>
                                                {isValidElement(content)
                                                    ? content
                                                    : content as string}
                                            </HelpDrawerExplanatoryContentText>
                                        </HelpDrawerExplanatoryContentContainer>
                                        {videoLink && (
                                            <HelpDrawerVideoContainer>
                                                <iframe width="560" height="315" src={videoLink} title="Ruminati Help" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                            </HelpDrawerVideoContainer>
                                        )}
                                        {accordionItems && (
                                            <Accordion>
                                                {accordionItems.map((item) => {
                                                    return (
                                                        <AccordionItem
                                                            renderTitle={() => (
                                                                <AccordionItemTitle>{item.title}</AccordionItemTitle>
                                                            )}
                                                            renderContent={() => (
                                                                <AccordionItemContentText>{item.content}</AccordionItemContentText>
                                                            )}
                                                        />
                                                    )
                                                })}
                                            </Accordion>
                                        )}
                                    </HelpDrawerContentWrapper>
                                )
                            }
                        }}
                    />
                )}
            </FormPageWrapper>
        </Screen>
    );
}

const FormPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    min-height: calc(100vh - 200px);
    padding: 20px 0px;
`;

const FormPageContainer = styled.div`
    width: calc(100vw - 470px);

    @media (max-width: 768px) {
        width: calc(100vw - 40px);
    }

    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        width: 100%;
    }
`;