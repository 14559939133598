import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Screen from "./screen";
import { useDatabaseStore } from "./../state/database_store";
import Heading from "./../components/heading";
import HorizontalDivider from "./../components/horizontal_divider";
import MainButton from "./../components/buttons/main_button";
import { Column, Row } from "./../components/styled_layout";
import { Property, PropertyType, Report } from "../models/property_models";
import EmptyPlaceholder from "./../components/empty_placeholder";

import LabelledValue from "../components/form/labelled_value";
import { ruminatiColors } from "../utilities/colors";
import { getReportsOfProperty } from "../utilities/reports";
import { BaseText } from "../components/styled_text";
import Icon from "../components/icon";
import DropdownMultiselectGroup from "../components/dropdown_multiselect";
import EnterpriseSummaryCard from "../components/cards/enterprise_summary";
import { useAuthStore } from "../state/auth_store";
import { UserPreferences } from "../models/api_models";
import { saveUserPreference } from "../services/auth_service";
import NotificationCard from "../components/cards/notification_card";

export enum FarmsPopupId {
    BasePlanLimit = 'base-plan-limit',
    DeleteFarm = 'delete-farm'
}

enum PropertyFilterTypes {
    InProgress = "In Progress",
    Farms = "Producers",
    Processors = "Processors",
    Feedlots = "Feedlots"
}

/**
 * The farms screen, which shows the users current farms
 * @returns the Farms Screen component
 */
export default function FarmsScreen() {
    const navigate = useNavigate();

    const databaseStore = useDatabaseStore();

    const reports: Report[] | undefined = databaseStore.reports;

    const [chosenOptions, setChosenOptions] = useState<string[]>([]);

    const authStore = useAuthStore()
    const user = authStore.user
    const [dismissedEnterpriseGetStarted, setDismissedEnterpriseGetStarted] = useState<boolean>(user?.userPreferences?.notifications?.dismissedEnterpriseGetStarted ?? false)

    function getFarmsCount(): number {
        if (databaseStore.properties === undefined) return 0;
        return databaseStore.properties.length;
    }

    let propertyList: Property[] | undefined = databaseStore.properties;
    if (propertyList && chosenOptions.length > 0) {

        if (chosenOptions.includes(PropertyFilterTypes.InProgress)) {
            propertyList = propertyList.filter(property => getReportsOfProperty(reports ?? [], property.id)[0]?.complete === false);
        }

        if (chosenOptions.filter((option) => option !== PropertyFilterTypes.InProgress).length > 0) {
            propertyList = propertyList.filter(property => {
                return chosenOptions.map((option: string) => {
                    switch (option) {
                        case (PropertyFilterTypes.Farms):
                            return property.propertyType === PropertyType.Farm;
                        case (PropertyFilterTypes.Processors):
                            return (property.propertyType === PropertyType.Processor || property.propertyType === PropertyType.FurtherProcessor);
                        case (PropertyFilterTypes.Feedlots):
                            return property.propertyType === PropertyType.Feedlot;
                        default:
                            return false;
                    }
                }).reduce((value, current) => current || value, false)
            })
        }
    }

    const specificPropertyList = (propertyList: Property[] | undefined, propertyType: PropertyType[], dividerText: string) => {
        propertyList = propertyList?.filter(property => propertyType.indexOf(property.propertyType) > -1);
        if (propertyList && propertyList.length > 0) {
            const propertyCards = propertyList.map((p: Property) => {
                return (
                    <Row style={{ marginBottom: "16px" }} key={p.id}>
                        <EnterpriseSummaryCard property={p} />
                    </Row>
                )
            })

            return (
                <>
                    <EnterpriseDivider
                        text={dividerText}
                    />
                    {propertyCards}
                </>
            )
        }
    }

    const saveNotification = async () => {
        try {
            if (!user) return;
            setDismissedEnterpriseGetStarted(true);

            const modifiedUserPreferences: UserPreferences = {
                ...user.userPreferences,
                notifications: {
                    ...user.userPreferences?.notifications,
                    dismissedEnterpriseGetStarted: true
                }
            };

            const response = await saveUserPreference(modifiedUserPreferences);
            if (response) authStore.signIn(response);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Screen pageTitle="Enterprises">
            <FarmsWrapper>
                <FarmsContainer>
                    {dismissedEnterpriseGetStarted === false &&
                        <Row style={{ marginBottom: '20px' }}>
                            <NotificationCard
                                title={"Welcome to Ruminati"}
                                description={"Ruminati lets you keep track of your emissions across all your enterprises, including for Cattle, Sheep and Crops, Feedlots and Processors. For each enterprise you can generate an emissions report and begin tracking and planning for your future."}
                                onClick={saveNotification}
                            />
                        </Row>
                    }
                    <Row
                        style={{
                            marginBottom: "20px",
                            justifyContent: "space-between",
                        }}
                    >
                        <Column style={{ alignItems: "start" }}>
                            <LabelledValue
                                label="Enterprises"
                                labelHeading={3}
                                labelColor={ruminatiColors.light_green}
                                spacerWidth="32px"
                                spacerMargin="8px"
                                dashStyle="center"
                            >
                                <Heading level={4} color={ruminatiColors.light_green}>
                                    {getFarmsCount()} total{" "}
                                </Heading>
                            </LabelledValue>
                        </Column>
                        <Column style={{ alignItems: "end" }}>
                            <MainButton
                                colorScheme="green"
                                size="small"
                                onClick={() => navigate(`/getstarted`)}
                            >
                                <div style={{ padding: "0 8px 0 4px" }}>
                                    <Icon icon="add" />
                                </div>
                                Add new
                            </MainButton>
                        </Column>
                    </Row>

                    <Row
                        style={{
                            marginBottom: "0px",
                            justifyContent: "start",
                        }}
                    >
                        <DropdownMultiselectGroup
                            key={chosenOptions.toString()}
                            menus={[
                                {
                                    buttonText: "Filter by",
                                    options: [PropertyFilterTypes.InProgress, PropertyFilterTypes.Farms, PropertyFilterTypes.Processors, PropertyFilterTypes.Feedlots],
                                    searchable: false,
                                    searchPlaceholder: "Search",
                                },
                            ]}
                            chosenOptions={chosenOptions}
                            onApply={(options: string[]) => { setChosenOptions(options); }}
                        />
                    </Row>

                    {specificPropertyList(propertyList, [PropertyType.Farm], 'Producers')}
                    {specificPropertyList(propertyList, [PropertyType.Dairy], 'Dairy')}
                    {specificPropertyList(propertyList, [PropertyType.Feedlot], 'Feedlots')}
                    {specificPropertyList(propertyList, [PropertyType.Processor, PropertyType.FurtherProcessor], 'Processors & Further Processors')}
                    {propertyList?.length === 0 &&
                        <EmptyPlaceholder>
                            No Enterprises Found!
                        </EmptyPlaceholder>
                    }

                </FarmsContainer>
            </FarmsWrapper>
        </Screen>
    );
}

function EnterpriseDivider({ text }: { text: string }) {
    return (<HorizontalDivider padding={"32px 0px 32px"}>
        <BaseText style={{ color: ruminatiColors.green_3, marginTop: "-7px" }}>{text}</BaseText>
    </HorizontalDivider>);
}

const FarmsWrapper = styled.div`
    display: flex;
    align-items: center;

    flex-direction: column;

    width: 100%;
    flex-grow: 1;

    padding-top: 60px;
`;

const FarmsContainer = styled.div`
    width: 90%;
    max-width: 936px;
    text-align: left;
    padding-bottom: 60px;
`;
