export const fertiliserOptions = [
  {
    "id": 1,
    "name": "42% Gran-Am 58% Urea",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.35,
    "nitrogenControl": 0.38,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 0.42,
    "urea": 0.27,
    "ureaPercent": 0.58,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.1
  },
  {
    "id": 2,
    "name": "43% MAP TE 57% Urea",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.31,
    "nitrogenControl": 0.31,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.43,
    "urea": 0.26,
    "ureaPercent": 0.57,
    "pPercent": 0.09,
    "kPercent": 0,
    "sPercent": 0.01
  },
  {
    "id": 3,
    "name": "50% Granulock Z 50% MOP - Ameliorant",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.06,
    "nitrogenControl": 0.06,
    "nonUreaNitrogen": 0.06,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.11,
    "kPercent": 0.25,
    "sPercent": 0.02
  },
  {
    "id": 4,
    "name": "50% Granulock Z 50% Muriate of Potash",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.06,
    "nitrogenControl": 0.06,
    "nonUreaNitrogen": 0.06,
    "nonUreaNitrogenPercent": 0.58,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.11,
    "kPercent": 0.25,
    "sPercent": 0.02
  },
  {
    "id": 5,
    "name": "50% MAP 50% SOA",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.16,
    "nitrogenControl": 0.16,
    "nonUreaNitrogen": 0.16,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.11,
    "kPercent": 0,
    "sPercent": 0.13
  },
  {
    "id": 6,
    "name": "50% MAP TE 50% Urea",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.28,
    "nitrogenControl": 0.29,
    "nonUreaNitrogen": 0.06,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0.23,
    "ureaPercent": 0.5,
    "pPercent": 0.11,
    "kPercent": 0,
    "sPercent": 0.01
  },
  {
    "id": 7,
    "name": "50% Urea & 50% SOA",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.34,
    "nitrogenControl": 0.34,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0.23,
    "ureaPercent": 0.5,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.12
  },
  {
    "id": 8,
    "name": "50% Urea 50% GranAm",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.33,
    "nitrogenControl": 0.36,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0.23,
    "ureaPercent": 0.5,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.12
  },
  {
    "id": 9,
    "name": "50% Urea 50% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.23,
    "nitrogenControl": 0.23,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.23,
    "ureaPercent": 0.5,
    "pPercent": 0,
    "kPercent": 0.25,
    "sPercent": 0
  },
  {
    "id": 10,
    "name": "60% MAP TE 40% Urea",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.24,
    "nitrogenControl": 0.25,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.6,
    "urea": 0.18,
    "ureaPercent": 0.4,
    "pPercent": 0.13,
    "kPercent": 0,
    "sPercent": 0.02
  },
  {
    "id": 11,
    "name": "60% Urea 40% SOA",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.36,
    "nitrogenControl": 0.36,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.4,
    "urea": 0.28,
    "ureaPercent": 0.6,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.1
  },
  {
    "id": 12,
    "name": "63% UreaPlus 37% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.29,
    "nitrogenControl": 0.29,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.29,
    "ureaPercent": 0.63,
    "pPercent": 0,
    "kPercent": 0.19,
    "sPercent": 0.06
  },
  {
    "id": 13,
    "name": "66% Urea 12% MAP 22% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.32,
    "nitrogenControl": 0.32,
    "nonUreaNitrogen": 0.01,
    "nonUreaNitrogenPercent": 0.12,
    "urea": 0.3,
    "ureaPercent": 0.66,
    "pPercent": 0.03,
    "kPercent": 0.11,
    "sPercent": 0
  },
  {
    "id": 14,
    "name": "66% Urea 21% Maxam 13% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.35,
    "nitrogenControl": 0.36,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.21,
    "urea": 0.3,
    "ureaPercent": 0.66,
    "pPercent": 0,
    "kPercent": 0.07,
    "sPercent": 0.05
  },
  {
    "id": 15,
    "name": "67% MAPZ2 33% SOP",
    "unit": "Kg/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.67,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.15,
    "kPercent": 0.14,
    "sPercent": 0.07
  },
  {
    "id": 16,
    "name": "70% MAP 30% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.08,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.7,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.15,
    "kPercent": 0.15,
    "sPercent": 0
  },
  {
    "id": 17,
    "name": "70% MAP TE 30% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.08,
    "nitrogenControl": 0.08,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.7,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.15,
    "kPercent": 0.15,
    "sPercent": 0.02
  },
  {
    "id": 18,
    "name": "70% Urea 30% SOA",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.39,
    "nitrogenControl": 0.39,
    "nonUreaNitrogen": 0.06,
    "nonUreaNitrogenPercent": 0.3,
    "urea": 0.32,
    "ureaPercent": 0.7,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.07
  },
  {
    "id": 19,
    "name": "73% UreaPlus 27% Muriate Of Potash",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.34,
    "nitrogenControl": 0.34,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.34,
    "ureaPercent": 0.73,
    "pPercent": 0,
    "kPercent": 0.14,
    "sPercent": 0
  },
  {
    "id": 20,
    "name": "75% MAP 25% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.08,
    "nitrogenControl": 0.08,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.75,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.17,
    "kPercent": 0.13,
    "sPercent": 0
  },
  {
    "id": 21,
    "name": "75% Urea 25% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.35,
    "nitrogenControl": 0.35,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.35,
    "ureaPercent": 0.75,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 22,
    "name": "80% Urea 20% MAP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.39,
    "nitrogenControl": 0.39,
    "nonUreaNitrogen": 0.02,
    "nonUreaNitrogenPercent": 0.2,
    "urea": 0.37,
    "ureaPercent": 0.8,
    "pPercent": 0.04,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 23,
    "name": "Activate N",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.18,
    "nitrogenControl": 0.18,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.28,
    "urea": 0.1,
    "ureaPercent": 0.21,
    "pPercent": 0.21,
    "kPercent": 0,
    "sPercent": 0.05
  },
  {
    "id": 24,
    "name": "Activist Max Zinc",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.09,
    "nonUreaNitrogen": 0.09,
    "nonUreaNitrogenPercent": 0.35,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 25,
    "name": "Agflow",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.13,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.38,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.17,
    "kPercent": 0,
    "sPercent": 0.06
  },
  {
    "id": 26,
    "name": "Agflow Boost",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.12,
    "nitrogenControl": 0.12,
    "nonUreaNitrogen": 0.12,
    "nonUreaNitrogenPercent": 0.35,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.2,
    "kPercent": 0,
    "sPercent": 0.04
  },
  {
    "id": 27,
    "name": "Agflow Extra",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.13,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.38,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.18,
    "kPercent": 0,
    "sPercent": 0.06
  },
  {
    "id": 28,
    "name": "AgNP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.11,
    "nitrogenControl": 0.11,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 0.32,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.23,
    "kPercent": 0,
    "sPercent": 0.01
  },
  {
    "id": 29,
    "name": "AllStar",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.13,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.38,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.14,
    "kPercent": 0,
    "sPercent": 0.09
  },
  {
    "id": 30,
    "name": "Ammo 36",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.36,
    "nitrogenControl": 1.09,
    "nonUreaNitrogen": 0.49,
    "nonUreaNitrogenPercent": 1.9,
    "urea": 0.6,
    "ureaPercent": 1.3,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.09
  },
  {
    "id": 31,
    "name": "Apex GaIXe Low P 21-1-6",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.21,
    "nitrogenControl": 0.21,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.2,
    "urea": 0.14,
    "ureaPercent": 0.32,
    "pPercent": 0.01,
    "kPercent": 0.05,
    "sPercent": 0.06
  },
  {
    "id": 32,
    "name": "Big N",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.82,
    "nitrogenControl": 0.82,
    "nonUreaNitrogen": 0.82,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 33,
    "name": "Big Phos",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.14,
    "kPercent": 0,
    "sPercent": 0.08
  },
  {
    "id": 34,
    "name": "Campbells Quick-N",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.26,
    "nitrogenControl": 0.26,
    "nonUreaNitrogen": 0.26,
    "nonUreaNitrogenPercent": 0.76,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.13
  },
  {
    "id": 35,
    "name": "CK 55 Ameliorant",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.14,
    "nitrogenControl": 0.14,
    "nonUreaNitrogen": 0.14,
    "nonUreaNitrogenPercent": 0.4,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.15,
    "kPercent": 0.13,
    "sPercent": 0.01
  },
  {
    "id": 36,
    "name": "Crop Booster Plus",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.05,
    "nitrogenControl": 0.05,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.14,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.15,
    "kPercent": 0.21,
    "sPercent": 0
  },
  {
    "id": 37,
    "name": "DAP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.18,
    "nitrogenControl": 0.18,
    "nonUreaNitrogen": 0.18,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.2,
    "kPercent": 0,
    "sPercent": 0.02
  },
  {
    "id": 38,
    "name": "DAPSZC",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.16,
    "nitrogenControl": 0.16,
    "nonUreaNitrogen": 0.16,
    "nonUreaNitrogenPercent": 0.88,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.18,
    "kPercent": 0,
    "sPercent": 0.08
  },
  {
    "id": 39,
    "name": "Easy N Bulk",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.43,
    "nitrogenControl": 0.43,
    "nonUreaNitrogen": 0.23,
    "nonUreaNitrogenPercent": 0.67,
    "urea": 0.21,
    "ureaPercent": 0.45,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 40,
    "name": "Entec Urea",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.46,
    "nitrogenControl": 0.46,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.46,
    "ureaPercent": 1,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 41,
    "name": "Ezyflow Nano Calbud",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.05,
    "nitrogenControl": 0.05,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.41,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 42,
    "name": "Flexi-N NI only",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.45,
    "nitrogenControl": 0.45,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.45,
    "ureaPercent": 0.98,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 43,
    "name": "Glytrel ZnP 0-7-0",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.07,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 44,
    "name": "Gran-Am",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.2,
    "nitrogenControl": 0.2,
    "nonUreaNitrogen": 0.2,
    "nonUreaNitrogenPercent": 0.76,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.24
  },
  {
    "id": 45,
    "name": "Granulock SS",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.1,
    "nitrogenControl": 0.11,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.18,
    "kPercent": 0,
    "sPercent": 0.12
  },
  {
    "id": 46,
    "name": "Granulock Z",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.11,
    "nitrogenControl": 0.12,
    "nonUreaNitrogen": 0.12,
    "nonUreaNitrogenPercent": 1.1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.22,
    "kPercent": 0,
    "sPercent": 0.04
  },
  {
    "id": 47,
    "name": "Green Urea NV",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.46,
    "nitrogenControl": 0.46,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.46,
    "ureaPercent": 1,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 48,
    "name": "Gypsum Sulphur",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.19
  },
  {
    "id": 49,
    "name": "Invigor8 0-37-37",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.37,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 50,
    "name": "Manganese Sulphate",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.18
  },
  {
    "id": 51,
    "name": "MAP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.1,
    "nitrogenControl": 0.11,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.22,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 52,
    "name": "MAP Impact",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.1,
    "nitrogenControl": 0.11,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.22,
    "kPercent": 0,
    "sPercent": 0.02
  },
  {
    "id": 53,
    "name": "MAPSZC",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.12,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 1.2,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.21,
    "kPercent": 0,
    "sPercent": 0.04
  },
  {
    "id": 54,
    "name": "Maximum N-Pact 29-0-0",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.29,
    "nitrogenControl": 0.29,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.29,
    "ureaPercent": 0.63,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 55,
    "name": "Microsol 12-6-44",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.12,
    "nitrogenControl": 0.12,
    "nonUreaNitrogen": 0.12,
    "nonUreaNitrogenPercent": 0.35,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.06,
    "kPercent": 0.44,
    "sPercent": 0.07
  },
  {
    "id": 56,
    "name": "Microsol 16-32-16",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.16,
    "nitrogenControl": 0.16,
    "nonUreaNitrogen": 0.02,
    "nonUreaNitrogenPercent": 0.07,
    "urea": 0.13,
    "ureaPercent": 0.29,
    "pPercent": 0.32,
    "kPercent": 0.16,
    "sPercent": 0.07
  },
  {
    "id": 57,
    "name": "Microsol 25-5-10",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.25,
    "nitrogenControl": 0.25,
    "nonUreaNitrogen": 0.12,
    "nonUreaNitrogenPercent": 0.35,
    "urea": 0.13,
    "ureaPercent": 0.28,
    "pPercent": 0.05,
    "kPercent": 0.1,
    "sPercent": 0.07
  },
  {
    "id": 58,
    "name": "Microsol 28-0-14",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.28,
    "nitrogenControl": 0.28,
    "nonUreaNitrogen": 0.04,
    "nonUreaNitrogenPercent": 0.12,
    "urea": 0.24,
    "ureaPercent": 0.52,
    "pPercent": 0,
    "kPercent": 0.14,
    "sPercent": 0.07
  },
  {
    "id": 59,
    "name": "Mono Potassium Phosphate MKP",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.23,
    "kPercent": 0.28,
    "sPercent": 0
  },
  {
    "id": 60,
    "name": "Muriate Of Potash MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0.5,
    "sPercent": 0
  },
  {
    "id": 61,
    "name": "NitroPlus",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.18,
    "nitrogenControl": 0.18,
    "nonUreaNitrogen": 0.03,
    "nonUreaNitrogenPercent": 0.09,
    "urea": 0.15,
    "ureaPercent": 0.32,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.05
  },
  {
    "id": 62,
    "name": "NutraGold",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.9
  },
  {
    "id": 63,
    "name": "Pro Performance 18-3-6",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.18,
    "nitrogenControl": 0.18,
    "nonUreaNitrogen": 0.1,
    "nonUreaNitrogenPercent": 0.29,
    "urea": 0.08,
    "ureaPercent": 0.17,
    "pPercent": 0.03,
    "kPercent": 0.5,
    "sPercent": 0
  },
  {
    "id": 64,
    "name": "SOA",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.21,
    "nitrogenControl": 0.21,
    "nonUreaNitrogen": 0.21,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.24
  },
  {
    "id": 65,
    "name": "SSP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.09,
    "kPercent": 0,
    "sPercent": 0.11
  },
  {
    "id": 66,
    "name": "Super Phos",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.09,
    "kPercent": 0,
    "sPercent": 0.11
  },
  {
    "id": 67,
    "name": "Super SR Extra",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.1,
    "kPercent": 0,
    "sPercent": 0.09
  },
  {
    "id": 68,
    "name": "SuPerfect",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.09,
    "kPercent": 0,
    "sPercent": 0.11
  },
  {
    "id": 69,
    "name": "SuperPasture Potash 5:1",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.08,
    "kPercent": 0.08,
    "sPercent": 0.09
  },
  {
    "id": 70,
    "name": "Trace-it Mn",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.11
  },
  {
    "id": 71,
    "name": "UniSZon",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.12,
    "nitrogenControl": 0.12,
    "nonUreaNitrogen": 0.12,
    "nonUreaNitrogenPercent": 0.35,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.21,
    "kPercent": 0,
    "sPercent": 0.05
  },
  {
    "id": 72,
    "name": "Urea",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.46,
    "nitrogenControl": 0.46,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.46,
    "ureaPercent": 1,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 73,
    "name": "Wengfu Slam Granular",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.19,
    "nitrogenControl": 0.19,
    "nonUreaNitrogen": 0.19,
    "nonUreaNitrogenPercent": 0.55,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.01,
    "kPercent": 0,
    "sPercent": 0.23
  },
  {
    "id": 74,
    "name": "Yara FlowPhos 10",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.13,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.15,
    "urea": 0.08,
    "ureaPercent": 0.17,
    "pPercent": 0.1,
    "kPercent": 0.05,
    "sPercent": 0
  },
  {
    "id": 75,
    "name": "Yara FlowPhos 13Z",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.09,
    "nitrogenControl": 0.09,
    "nonUreaNitrogen": 0.09,
    "nonUreaNitrogenPercent": 0.26,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.14,
    "kPercent": 0.01,
    "sPercent": 0
  },
  {
    "id": 76,
    "name": "Yara FlowPhos 15",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.1,
    "nitrogenControl": 0.1,
    "nonUreaNitrogen": 0.1,
    "nonUreaNitrogenPercent": 0.29,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.15,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 77,
    "name": "Yara FlowPhos KZ",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.08,
    "nitrogenControl": 0.08,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.24,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.14,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 78,
    "name": "Yara KeyPro Trace",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0.04,
    "sPercent": 0
  },
  {
    "id": 79,
    "name": "Yara KeyPro Zinc",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0.09,
    "sPercent": 0
  },
  {
    "id": 80,
    "name": "Yara K-Flow 0-0-15",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0.15,
    "sPercent": 0
  },
  {
    "id": 81,
    "name": "Yara K-Flow 0-14-30",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.14,
    "kPercent": 0.3,
    "sPercent": 0
  },
  {
    "id": 82,
    "name": "Yara K-Flow 0-7-36",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.07,
    "kPercent": 0.36,
    "sPercent": 0
  },
  {
    "id": 83,
    "name": "Yara K-Flow 13-0-7",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.13,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.13,
    "ureaPercent": 0.29,
    "pPercent": 0,
    "kPercent": 0.07,
    "sPercent": 0
  },
  {
    "id": 84,
    "name": "Yara K-Flow 4-0-12",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.04,
    "nitrogenControl": 0.04,
    "nonUreaNitrogen": 0.04,
    "nonUreaNitrogenPercent": 0.13,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0.12,
    "sPercent": 0
  },
  {
    "id": 85,
    "name": "Yara Liquids MICRO-SUL",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.07,
    "ureaPercent": 0.15,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.07
  },
  {
    "id": 86,
    "name": "Yara Liquids N24",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.24,
    "nitrogenControl": 0.24,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.24,
    "ureaPercent": 0.52,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 87,
    "name": "Yara Liquids N25",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.25,
    "nitrogenControl": 0.25,
    "nonUreaNitrogen": 0.25,
    "nonUreaNitrogenPercent": 0.89,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 88,
    "name": "Yara Liquids N26",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.26,
    "nitrogenControl": 0.26,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.26,
    "ureaPercent": 0.57,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 89,
    "name": "Yara Liquids N42",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.42,
    "nitrogenControl": 0.38,
    "nonUreaNitrogen": 0.17,
    "nonUreaNitrogenPercent": 0.6,
    "urea": 0.21,
    "ureaPercent": 0.47,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 90,
    "name": "Yara Liquids N-CAL 15+18Ca",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.15,
    "nitrogenControl": 0.15,
    "nonUreaNitrogen": 0.15,
    "nonUreaNitrogenPercent": 0.44,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 91,
    "name": "Yara Liquids N-CAL 20+10Ca+B",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.2,
    "nitrogenControl": 0.2,
    "nonUreaNitrogen": 0.2,
    "nonUreaNitrogenPercent": 0.58,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 92,
    "name": "Yara Liquids N-CAL 35+6Ca",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.35,
    "nitrogenControl": 0.35,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.16,
    "urea": 0.3,
    "ureaPercent": 0.65,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 93,
    "name": "Yara Liquids N-FOL 24",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.24,
    "nitrogenControl": 0.24,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.24,
    "ureaPercent": 0.52,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 94,
    "name": "Yara Liquids N-FOL 24+TE",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.25,
    "nitrogenControl": 0.25,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.25,
    "ureaPercent": 0.53,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.01
  },
  {
    "id": 95,
    "name": "Yara Liquids P-FOL Zinc",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.18,
    "kPercent": 0.02,
    "sPercent": 0
  },
  {
    "id": 96,
    "name": "Yara Liquids SULSA 11-0-0+12S",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.11,
    "nitrogenControl": 0.08,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.31,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.12
  },
  {
    "id": 97,
    "name": "Yara Liquids SULSA 24-3-0+6S",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.24,
    "nitrogenControl": 0.22,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.2,
    "urea": 0.17,
    "ureaPercent": 0.37,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.05
  },
  {
    "id": 98,
    "name": "Yara Liquids SULSA 27-0-0+7S",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.27,
    "nitrogenControl": 0.25,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.18,
    "urea": 0.21,
    "ureaPercent": 0.45,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.07
  },
  {
    "id": 99,
    "name": "Yara Liquids SULSA 36-0-0+3S",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.36,
    "nitrogenControl": 0.34,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.44,
    "urea": 0.21,
    "ureaPercent": 0.46,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.03
  },
  {
    "id": 100,
    "name": "YaraLiva CALCINIT B",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.16,
    "nitrogenControl": 0.16,
    "nonUreaNitrogen": 0.16,
    "nonUreaNitrogenPercent": 0.52,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 101,
    "name": "YaraLiva NITRABOR",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.15,
    "nitrogenControl": 0.15,
    "nonUreaNitrogen": 0.15,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 102,
    "name": "YaraLiva TROPICOTE",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.15,
    "nitrogenControl": 0.15,
    "nonUreaNitrogen": 0.15,
    "nonUreaNitrogenPercent": 0.49,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 103,
    "name": "YaraMila Complex",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.12,
    "nitrogenControl": 0.11,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 0.36,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.05,
    "kPercent": 0.15,
    "sPercent": 0.08
  },
  {
    "id": 104,
    "name": "YaraMila Complex 17-5-10(4)",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.17,
    "nitrogenControl": 0.15,
    "nonUreaNitrogen": 0.15,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.05,
    "kPercent": 0.1,
    "sPercent": 0.04
  },
  {
    "id": 105,
    "name": "YaraMila Complex 21-7-3(4)",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.21,
    "nitrogenControl": 0.19,
    "nonUreaNitrogen": 0.19,
    "nonUreaNitrogenPercent": 0.62,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.07,
    "kPercent": 0.03,
    "sPercent": 0.04
  },
  {
    "id": 106,
    "name": "YaraMila UNIVERSAL",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.15,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.44,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.07,
    "kPercent": 0.13,
    "sPercent": 0.05
  },
  {
    "id": 107,
    "name": "YaraRega 13-2-21",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.13,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.42,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.02,
    "kPercent": 0.21,
    "sPercent": 0.09
  },
  {
    "id": 108,
    "name": "YaraVita BORTRAC",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.22,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 109,
    "name": "YaraVita BRASSITREL DF",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.01,
    "nitrogenControl": 0.01,
    "nonUreaNitrogen": 0.01,
    "nonUreaNitrogenPercent": 0.03,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.04
  },
  {
    "id": 110,
    "name": "YaraVita BRASSITREL PRO",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.22,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 111,
    "name": "YaraVita BUD BUILDER FL",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.22,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.02,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 112,
    "name": "YaraVita FRUTREL",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.22,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.11,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 113,
    "name": "YaraVita GLYTREL ZnP",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.04,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 114,
    "name": "YaraVita GRAMITREL",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.06,
    "nitrogenControl": 0.06,
    "nonUreaNitrogen": 0.06,
    "nonUreaNitrogenPercent": 0.19,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 115,
    "name": "YaraVita KOMBIPHOS",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.19,
    "kPercent": 0.06,
    "sPercent": 0
  },
  {
    "id": 116,
    "name": "YaraVita MANGZINC",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.04,
    "nitrogenControl": 0.04,
    "nonUreaNitrogen": 0.04,
    "nonUreaNitrogenPercent": 0.12,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 117,
    "name": "YaraVita MOLYTRAC",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.11,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 118,
    "name": "YaraVita SENIPHOS",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.14,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 119,
    "name": "YaraVita ZINTRAC",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.02,
    "nitrogenControl": 0.02,
    "nonUreaNitrogen": 0.02,
    "nonUreaNitrogenPercent": 0.05,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 120,
    "name": "Zinc Sulphate Monohydrate",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.17
  },
  {
    "id": 121,
    "name": "Zincsol",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.08
  },
  {
    "id": 122,
    "name": "ZincStar",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.1,
    "nitrogenControl": 0.11,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.22,
    "kPercent": 0,
    "sPercent": 0.02
  },
  {
    "id": 123,
    "name": "ZMC-1",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.08
  },
  {
    "id": 124,
    "name": "Zn 7000",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.07,
    "ureaPercent": 0.15,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  }
]

export type HerbicideOrHerbicidePesticideOption = {
  id: string
  name: string
  active_name: string
  active_amount: number
  classification: string
  type: string
  manufacturer: string
}

export const herbicideOptions: HerbicideOrHerbicidePesticideOption[] = [
  {
    "id": "Herbicide_1",
    "name": "Liberty 200",
    "active_name": "glufinosate-ammonium",
    "active_amount": 200,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "Herbicide_2",
    "name": "Basta",
    "active_name": "glufosinate - ammonium",
    "active_amount": 200,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "Herbicide_3",
    "name": "Crucial",
    "active_name": "glyphosate",
    "active_amount": 600,
    "classification": "B",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "Herbicide_4",
    "name": "Glyphosate 540",
    "active_name": "glyphosate",
    "active_amount": 540,
    "classification": "B",
    "type": "Herbicide",
    "manufacturer": "ADAMA"
  },
  {
    "id": "Herbicide_5",
    "name": "Roundup CT",
    "active_name": "glyphosate",
    "active_amount": 450,
    "classification": "B",
    "type": "Herbicide",
    "manufacturer": "Monsanto Australia Pty Ltd"
  },
  {
    "id": "Herbicide_6",
    "name": "Roundup Ready with Plantshield",
    "active_name": "glyphosate",
    "active_amount": 690,
    "classification": "B",
    "type": "Herbicide",
    "manufacturer": "Monsanto Australia Pty Ltd"
  },
  {
    "id": "Herbicide_7",
    "name": "Roundup ULTRA MAX",
    "active_name": "glyphosate",
    "active_amount": 570,
    "classification": "B",
    "type": "Herbicide",
    "manufacturer": "Monsanto Australia Pty Ltd"
  },
  {
    "id": "Herbicide_8",
    "name": "Weedmaster DST",
    "active_name": "glyphosate",
    "active_amount": 470,
    "classification": "B",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "Herbicide_9",
    "name": "Genfarm Paraquat 250",
    "active_name": "paraquat",
    "active_amount": 250,
    "classification": "B",
    "type": "Herbicide",
    "manufacturer": "Nutrien"
  },
  {
    "id": "Herbicide_10",
    "name": "Gramoxone 360 Pro",
    "active_name": "paraquat",
    "active_amount": 360,
    "classification": "B",
    "type": "Herbicide",
    "manufacturer": "Syngenta"
  },
  {
    "id": "Herbicide_11",
    "name": "Spray.Seed 250",
    "active_name": "paraquat diquat",
    "active_amount": 250,
    "classification": "B",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "Herbicide_12",
    "name": "Reglone",
    "active_name": "diquat",
    "active_amount": 200,
    "classification": "B",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  }
]

export const herbicidePesticideOptions: HerbicideOrHerbicidePesticideOption[] = [
  {
    "id": "HerbicidePesticide_1",
    "name": "4Farmers Sulfosulfuron",
    "active_name": "sulfosulfuron",
    "active_amount": 750,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "4 Farmers"
  },
  {
    "id": "HerbicidePesticide_2",
    "name": "AC Orca 700",
    "active_name": "",
    "active_amount": 753,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "AxiChem"
  },
  {
    "id": "HerbicidePesticide_3",
    "name": "Achieve WG",
    "active_name": "tralkoxydim",
    "active_amount": 400,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_4",
    "name": "Activator",
    "active_name": "",
    "active_amount": 900,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_5",
    "name": "Adigor",
    "active_name": "",
    "active_amount": 440,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_6",
    "name": "Agral",
    "active_name": "",
    "active_amount": 600,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_7",
    "name": "Agtryne MA",
    "active_name": "terbutryn MCPA",
    "active_amount": 435,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_8",
    "name": "Alliance",
    "active_name": "Amitrole paraquat",
    "active_amount": 375,
    "classification": "B",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_9",
    "name": "Altantis OD",
    "active_name": "mesosulfuron-methyl mefenpyr-diethyl",
    "active_amount": 120,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_10",
    "name": "Amicide Advance 700",
    "active_name": "2,4-D Amine",
    "active_amount": 700,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_11",
    "name": "Amine 625",
    "active_name": "2,4-D",
    "active_amount": 625,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_12",
    "name": "Amistar Xtra",
    "active_name": "azoxystrobin cyproconozole",
    "active_amount": 280,
    "classification": "A",
    "type": "Fungicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_13",
    "name": "Amitrole T",
    "active_name": "Amitrole ammonium thiocyanate",
    "active_amount": 470,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_14",
    "name": "Associate",
    "active_name": "metsulfuron-methyl",
    "active_amount": 600,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_15",
    "name": "Atrazine 600 SC",
    "active_name": "atrazine",
    "active_amount": 600,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "4 Farmers"
  },
  {
    "id": "HerbicidePesticide_16",
    "name": "Aura",
    "active_name": "profoxydim",
    "active_amount": 200,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "BASF Australia Ltd"
  },
  {
    "id": "HerbicidePesticide_17",
    "name": "Avadex Extra",
    "active_name": "tri-allate",
    "active_amount": 500,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_18",
    "name": "Aviator Xpro",
    "active_name": "bixafen prothioconazole",
    "active_amount": 225,
    "classification": "A",
    "type": "Fungicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_19",
    "name": "Axial 100EC",
    "active_name": "pinoxaden cloquintocet",
    "active_amount": 125,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_20",
    "name": "Balance 750 WG",
    "active_name": "isoxaflutole",
    "active_amount": 750,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_21",
    "name": "Barrage",
    "active_name": "diuron hexazinone",
    "active_amount": 600,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_22",
    "name": "Bentley",
    "active_name": "bromoxynil diflufenican",
    "active_amount": 275,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_23",
    "name": "Bladex 900 WG",
    "active_name": "cyanazine",
    "active_amount": 900,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Agnova"
  },
  {
    "id": "HerbicidePesticide_24",
    "name": "Bolt MA",
    "active_name": "bromoxynil MCPA",
    "active_amount": 400,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nutrien"
  },
  {
    "id": "HerbicidePesticide_25",
    "name": "Bond",
    "active_name": "",
    "active_amount": 550,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_26",
    "name": "Boxer Gold",
    "active_name": "prosulfocarb s-metalchlor",
    "active_amount": 920,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_27",
    "name": "Broadside",
    "active_name": "MCPA bromoxynil dicamba",
    "active_amount": 460,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_28",
    "name": "Broadstrike",
    "active_name": "flumetsulam",
    "active_amount": 800,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Dow Agrosciences"
  },
  {
    "id": "HerbicidePesticide_29",
    "name": "Brodel Options",
    "active_name": "diflufenican",
    "active_amount": 500,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_30",
    "name": "Bromocide",
    "active_name": "bromoxynil",
    "active_amount": 848,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_31",
    "name": "BS 1000",
    "active_name": "",
    "active_amount": 1000,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_32",
    "name": "Butisan",
    "active_name": "metazachlor",
    "active_amount": 500,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "BASF Australia Ltd"
  },
  {
    "id": "HerbicidePesticide_33",
    "name": "Buttress",
    "active_name": "2,4-DB",
    "active_amount": 500,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_34",
    "name": "Cadence",
    "active_name": "dicamba",
    "active_amount": 700,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_35",
    "name": "Callisto",
    "active_name": "mesotrione",
    "active_amount": 480,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_36",
    "name": "Captan 900",
    "active_name": "captan",
    "active_amount": 900,
    "classification": "A",
    "type": "Fungicide",
    "manufacturer": "Genfarm"
  },
  {
    "id": "HerbicidePesticide_37",
    "name": "Cheetah Gold",
    "active_name": "diclofop sethoxydim fenoxaprop-ehtyl",
    "active_amount": 233.6,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Sipcam"
  },
  {
    "id": "HerbicidePesticide_38",
    "name": "Chemwet 1000",
    "active_name": "",
    "active_amount": 1000,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Vicchem"
  },
  {
    "id": "HerbicidePesticide_39",
    "name": "Clethodim 240EC",
    "active_name": "clethodim",
    "active_amount": 240,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Genfarm"
  },
  {
    "id": "HerbicidePesticide_40",
    "name": "Combi SC",
    "active_name": "atrazine ametryn",
    "active_amount": 500,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Sipcam"
  },
  {
    "id": "HerbicidePesticide_41",
    "name": "Cotogard",
    "active_name": "fluometron prometryn",
    "active_amount": 880,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "ADAMA"
  },
  {
    "id": "HerbicidePesticide_42",
    "name": "Cotoran WG",
    "active_name": "fluometuron",
    "active_amount": 900,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "ADAMA"
  },
  {
    "id": "HerbicidePesticide_43",
    "name": "Cruiser 350",
    "active_name": "thiamethoxan",
    "active_amount": 350,
    "classification": "A",
    "type": "Insecticide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_44",
    "name": "Crusader GoDRI",
    "active_name": "cloquintocet pyroxsulam",
    "active_amount": 666.5,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Dow Agrosciences"
  },
  {
    "id": "HerbicidePesticide_45",
    "name": "Daconate 720",
    "active_name": "MSMA",
    "active_amount": 720,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_46",
    "name": "Dacthal 900 WG",
    "active_name": "chlorthal-dimethyl",
    "active_amount": 900,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_47",
    "name": "D-C-Trate Newgen",
    "active_name": "",
    "active_amount": 870,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Caltex"
  },
  {
    "id": "HerbicidePesticide_48",
    "name": "D-C-Tron Cotton",
    "active_name": "",
    "active_amount": 827,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Caltex"
  },
  {
    "id": "HerbicidePesticide_49",
    "name": "Decision",
    "active_name": "diclofop-methyl sethoxydim",
    "active_amount": 220,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Sipcam"
  },
  {
    "id": "HerbicidePesticide_50",
    "name": "Deluge",
    "active_name": "",
    "active_amount": 950,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Vicchem"
  },
  {
    "id": "HerbicidePesticide_51",
    "name": "Dictate 480",
    "active_name": "bentazone",
    "active_amount": 480,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_52",
    "name": "Dictate M60",
    "active_name": "bentazone MCPA",
    "active_amount": 460,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_53",
    "name": "Difenocnazole 250",
    "active_name": "Difenonazole",
    "active_amount": 250,
    "classification": "A",
    "type": "Fungicide",
    "manufacturer": "Genfarm"
  },
  {
    "id": "HerbicidePesticide_54",
    "name": "Director 480 EC",
    "active_name": "clomazone",
    "active_amount": 480,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_55",
    "name": "Diuron 500 SC",
    "active_name": "diuron",
    "active_amount": 500,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Sipcam"
  },
  {
    "id": "HerbicidePesticide_56",
    "name": "Dual Gold",
    "active_name": "s-metachlor",
    "active_amount": 960,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_57",
    "name": "Eclipse 100 SC",
    "active_name": "metosulam",
    "active_amount": 100,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_58",
    "name": "Ecopar",
    "active_name": "pyraflufen-ethyl",
    "active_amount": 20,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Sipcam"
  },
  {
    "id": "HerbicidePesticide_59",
    "name": "Eptam",
    "active_name": "ethyl dipropylthiocarbamate",
    "active_amount": 720,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_60",
    "name": "Estercide Xtra 680",
    "active_name": "2,4-D",
    "active_amount": 680,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_61",
    "name": "Express",
    "active_name": "tribenuron methyl",
    "active_amount": 750,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "FMC"
  },
  {
    "id": "HerbicidePesticide_62",
    "name": "Factor WG",
    "active_name": "butroxydim",
    "active_amount": 250,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_63",
    "name": "Farmozine 900 WG",
    "active_name": "atrazine",
    "active_amount": 900,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "ADAMA"
  },
  {
    "id": "HerbicidePesticide_64",
    "name": "Fastup",
    "active_name": "",
    "active_amount": 748,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Agrobest"
  },
  {
    "id": "HerbicidePesticide_65",
    "name": "Flamprop 90",
    "active_name": "flamprop-m-methyl",
    "active_amount": 90,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Titan"
  },
  {
    "id": "HerbicidePesticide_66",
    "name": "Flash 240",
    "active_name": "imazapic imazapyr",
    "active_amount": 240,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nutrien"
  },
  {
    "id": "HerbicidePesticide_67",
    "name": "Flutriafol",
    "active_name": "flutriafol",
    "active_amount": 500,
    "classification": "A",
    "type": "Fungicide",
    "manufacturer": "Genfarm"
  },
  {
    "id": "HerbicidePesticide_68",
    "name": "ForageMax",
    "active_name": "halauxifen aminopyralid",
    "active_amount": 150,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Dow Agrosciences"
  },
  {
    "id": "HerbicidePesticide_69",
    "name": "Frequency",
    "active_name": "topramezone cloquintocet-mexyl",
    "active_amount": 120,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "BASF Australia Ltd"
  },
  {
    "id": "HerbicidePesticide_70",
    "name": "Fusilade Forte 128 EC",
    "active_name": "fluazifop",
    "active_amount": 128,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_71",
    "name": "Garlon 600",
    "active_name": "triclopyr",
    "active_amount": 600,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Dow Agrosciences"
  },
  {
    "id": "HerbicidePesticide_72",
    "name": "Genfarm Dicamba 500",
    "active_name": "dicamba",
    "active_amount": 500,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nutrien"
  },
  {
    "id": "HerbicidePesticide_73",
    "name": "Genfarm Diuron 900WG",
    "active_name": "diuron",
    "active_amount": 900,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nutrien"
  },
  {
    "id": "HerbicidePesticide_74",
    "name": "Genfarm Miclofop-Methyl 375",
    "active_name": "diclofop-methyl",
    "active_amount": 375,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nutrien"
  },
  {
    "id": "HerbicidePesticide_75",
    "name": "Genfarm Simazine 900 WG",
    "active_name": "simazine",
    "active_amount": 900,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nutrien"
  },
  {
    "id": "HerbicidePesticide_76",
    "name": "Genfarm Terbuthylazine 875 WG",
    "active_name": "terbuthylazine",
    "active_amount": 875,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nutrien"
  },
  {
    "id": "HerbicidePesticide_77",
    "name": "Hammer 400EC",
    "active_name": "carfentrazone-ethyl",
    "active_amount": 866,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "FMC"
  },
  {
    "id": "HerbicidePesticide_78",
    "name": "Hasten",
    "active_name": "",
    "active_amount": 900,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Vicchem"
  },
  {
    "id": "HerbicidePesticide_79",
    "name": "Hotshot",
    "active_name": "fluroxypr amminopyralid",
    "active_amount": 150,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Dow Agrosciences"
  },
  {
    "id": "HerbicidePesticide_80",
    "name": "Hotup",
    "active_name": "",
    "active_amount": 670,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Vicchem"
  },
  {
    "id": "HerbicidePesticide_81",
    "name": "Hussar OD",
    "active_name": "iodosulfuron-methyl mefenpyr-diethyl",
    "active_amount": 400,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_82",
    "name": "Igran 500",
    "active_name": "terbutryn",
    "active_amount": 500,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_83",
    "name": "Inbound",
    "active_name": "",
    "active_amount": 870,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Vicchem"
  },
  {
    "id": "HerbicidePesticide_84",
    "name": "Intake",
    "active_name": "flutriafol",
    "active_amount": 500,
    "classification": "A",
    "type": "Fungicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_85",
    "name": "Intercept",
    "active_name": "imazamox imazapyr",
    "active_amount": 48,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_86",
    "name": "Kamba M",
    "active_name": "MCPA Dicamba",
    "active_amount": 420,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_87",
    "name": "Ken Chlor 750",
    "active_name": "chlorsulfuron",
    "active_amount": 750,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Kenso Corporation"
  },
  {
    "id": "HerbicidePesticide_88",
    "name": "Ken-Amine 720",
    "active_name": "2,4-D",
    "active_amount": 720,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Kenso Corporation"
  },
  {
    "id": "HerbicidePesticide_89",
    "name": "Kenso Agcare MCPA 750",
    "active_name": "MCPA",
    "active_amount": 750,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Kenso Corporation"
  },
  {
    "id": "HerbicidePesticide_90",
    "name": "Ken-Trel 300",
    "active_name": "clopyralid",
    "active_amount": 300,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Kenso Corporation"
  },
  {
    "id": "HerbicidePesticide_91",
    "name": "Krismat WG",
    "active_name": "Ametryn trifloxysulfuron",
    "active_amount": 750,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_92",
    "name": "Leopard 200",
    "active_name": "quizalofop-p-ethyl",
    "active_amount": 200,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "ADAMA"
  },
  {
    "id": "HerbicidePesticide_93",
    "name": "Liase",
    "active_name": "",
    "active_amount": 417,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_94",
    "name": "Lightning",
    "active_name": "imazethapyr imazapyr",
    "active_amount": 700,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "BASF Australia Ltd"
  },
  {
    "id": "HerbicidePesticide_95",
    "name": "Lontrel 750 SG",
    "active_name": "clopyralid",
    "active_amount": 750,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Dow Agrosciences"
  },
  {
    "id": "HerbicidePesticide_96",
    "name": "Lorox Linuron flowable",
    "active_name": "linuron",
    "active_amount": 480,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Tessenderlo"
  },
  {
    "id": "HerbicidePesticide_97",
    "name": "Loveland Products LI 700",
    "active_name": "",
    "active_amount": 700,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Nutrien"
  },
  {
    "id": "HerbicidePesticide_98",
    "name": "Luximax Herbicide",
    "active_name": "cinmethylin",
    "active_amount": 750,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "BASF Australia Ltd"
  },
  {
    "id": "HerbicidePesticide_99",
    "name": "MCPA 250",
    "active_name": "MCPA",
    "active_amount": 250,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_100",
    "name": "Nail 240EC",
    "active_name": "carfrentrazone-ethyl",
    "active_amount": 240,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_101",
    "name": "Ordram",
    "active_name": "molinate",
    "active_amount": 960,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_102",
    "name": "Outright 770",
    "active_name": "",
    "active_amount": 770,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Vicchem"
  },
  {
    "id": "HerbicidePesticide_103",
    "name": "Overwatch Herbicide",
    "active_name": "bixlozone",
    "active_amount": 400,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "FMC"
  },
  {
    "id": "HerbicidePesticide_104",
    "name": "Paradigm Arylex",
    "active_name": "halauxifen florasulam",
    "active_amount": 400,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Dow Agrosciences"
  },
  {
    "id": "HerbicidePesticide_105",
    "name": "Pixxaro",
    "active_name": "fluroxypyr halauxifen cloquintocet-mexyl",
    "active_amount": 759,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Corteva"
  },
  {
    "id": "HerbicidePesticide_106",
    "name": "Polo 750 LVE",
    "active_name": "MCPA",
    "active_amount": 570,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_107",
    "name": "Precept",
    "active_name": "MCPA pyrasulfotole mefenpyr-diethyl",
    "active_amount": 156.25,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_108",
    "name": "Primabuff",
    "active_name": "",
    "active_amount": 641.3,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_109",
    "name": "Primextra Gold",
    "active_name": "atrazine s-metalchlor",
    "active_amount": 680,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_110",
    "name": "Priority",
    "active_name": "florasulam",
    "active_amount": 200,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "ADAMA"
  },
  {
    "id": "HerbicidePesticide_111",
    "name": "Prometryn 900DF",
    "active_name": "prometryn",
    "active_amount": 900,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_112",
    "name": "Propyzamide 500 SC",
    "active_name": "propyzamide",
    "active_amount": 542,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Surefire"
  },
  {
    "id": "HerbicidePesticide_113",
    "name": "Propyzamide 500 WG",
    "active_name": "propyzamide",
    "active_amount": 500,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Genfarm"
  },
  {
    "id": "HerbicidePesticide_114",
    "name": "Prosaro 420",
    "active_name": "prothioconozole tebuconazole",
    "active_amount": 420,
    "classification": "A",
    "type": "Fungicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_115",
    "name": "Prothio T",
    "active_name": "prothioconozole tebuconazole",
    "active_amount": 420,
    "classification": "A",
    "type": "Fungicide",
    "manufacturer": "Genfarm"
  },
  {
    "id": "HerbicidePesticide_116",
    "name": "Pulse",
    "active_name": "",
    "active_amount": 1000,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_117",
    "name": "Rancona C",
    "active_name": "ipconazole cypermethrin",
    "active_amount": 24,
    "classification": "A",
    "type": "Fungicide",
    "manufacturer": "UPL"
  },
  {
    "id": "HerbicidePesticide_118",
    "name": "Raptor WG",
    "active_name": "imazamox",
    "active_amount": 700,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "ADAMA"
  },
  {
    "id": "HerbicidePesticide_119",
    "name": "Raxil-T",
    "active_name": "tebuconazole triflumuron",
    "active_amount": 29,
    "classification": "A",
    "type": "Fungicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_120",
    "name": "Reflex",
    "active_name": "fomesafen",
    "active_amount": 240,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_121",
    "name": "Rialto",
    "active_name": "triasulfuron",
    "active_amount": 750,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nutrien"
  },
  {
    "id": "HerbicidePesticide_122",
    "name": "Rifle 440",
    "active_name": "pendimethalin",
    "active_amount": 440,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_123",
    "name": "Ruslter 900 WG",
    "active_name": "propyzamide",
    "active_amount": 900,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "FMC"
  },
  {
    "id": "HerbicidePesticide_124",
    "name": "Sakura 850 WG",
    "active_name": "pyroxasulfone",
    "active_amount": 850,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Cropscience"
  },
  {
    "id": "HerbicidePesticide_125",
    "name": "Saturn EC",
    "active_name": "thiobencarb",
    "active_amount": 800,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Cropscience"
  },
  {
    "id": "HerbicidePesticide_126",
    "name": "Select Xtra",
    "active_name": "clethodim",
    "active_amount": 894,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "UPL"
  },
  {
    "id": "HerbicidePesticide_127",
    "name": "Sempra",
    "active_name": "halosulfuron-methyl",
    "active_amount": 750,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_128",
    "name": "Sencor 480 SC",
    "active_name": "metribuzin",
    "active_amount": 480,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_129",
    "name": "Sentry",
    "active_name": "imazapic imazapyr",
    "active_amount": 700,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_130",
    "name": "Shogun",
    "active_name": "propaquizafop",
    "active_amount": 100,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "ADAMA"
  },
  {
    "id": "HerbicidePesticide_131",
    "name": "Spinnaker 700WDG",
    "active_name": "imazethapyr",
    "active_amount": 700,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "BASF Australia Ltd"
  },
  {
    "id": "HerbicidePesticide_132",
    "name": "Spraytech oil",
    "active_name": "",
    "active_amount": 878,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Agrobest"
  },
  {
    "id": "HerbicidePesticide_133",
    "name": "Starane Advanced",
    "active_name": "fluroxypr",
    "active_amount": 333,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Dow Agrosciences"
  },
  {
    "id": "HerbicidePesticide_134",
    "name": "Status",
    "active_name": "clethodim",
    "active_amount": 240,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Sumitomo"
  },
  {
    "id": "HerbicidePesticide_135",
    "name": "Striker",
    "active_name": "oxyfluorfen",
    "active_amount": 240,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_136",
    "name": "Supercharge Elite",
    "active_name": "",
    "active_amount": 471,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_137",
    "name": "Taipan",
    "active_name": "benzofenap",
    "active_amount": 300,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_138",
    "name": "Talinor Herbicide",
    "active_name": "bromoxynil bicycloprone",
    "active_amount": 221.9,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_139",
    "name": "Talstar 250",
    "active_name": "Bifenthrin",
    "active_amount": 250,
    "classification": "A",
    "type": "Insecticide",
    "manufacturer": "FMC"
  },
  {
    "id": "HerbicidePesticide_140",
    "name": "Taskforce",
    "active_name": "fluproponate",
    "active_amount": 745,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Vee Dri"
  },
  {
    "id": "HerbicidePesticide_141",
    "name": "Terbyne Xtreme",
    "active_name": "terbuthylazine",
    "active_amount": 875,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Sipcam"
  },
  {
    "id": "HerbicidePesticide_142",
    "name": "Terrain 500 WG",
    "active_name": "flumioxazin",
    "active_amount": 500,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_143",
    "name": "Tigrex",
    "active_name": "MCPA Ddiflufenican",
    "active_amount": 250,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_144",
    "name": "Titan Bensulfuron 600WG",
    "active_name": "bensulfuron-methyl",
    "active_amount": 600,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Titan"
  },
  {
    "id": "HerbicidePesticide_145",
    "name": "Topik 240 EC",
    "active_name": "clodinafop-propargl cloquintocet-mexyl",
    "active_amount": 300,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_146",
    "name": "Tordon 75-D",
    "active_name": "2,4-D Picloram",
    "active_amount": 375,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Corteva"
  },
  {
    "id": "HerbicidePesticide_147",
    "name": "Transcend",
    "active_name": "metaldehyde fipronil",
    "active_amount": 51.5,
    "classification": "A",
    "type": "Insecticide",
    "manufacturer": "Imtrade"
  },
  {
    "id": "HerbicidePesticide_148",
    "name": "Trialsulfuron 520 + Butafenacil 200 WG",
    "active_name": "triasulfuron butafenacil",
    "active_amount": 720,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Titan"
  },
  {
    "id": "HerbicidePesticide_149",
    "name": "TriflurX",
    "active_name": "trifluralin",
    "active_amount": 480,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_150",
    "name": "Trojan",
    "active_name": "gamma-cyhalothrin",
    "active_amount": 150,
    "classification": "A",
    "type": "Insecticide",
    "manufacturer": "FMC"
  },
  {
    "id": "HerbicidePesticide_151",
    "name": "Trooper 242",
    "active_name": "MCPA picloram",
    "active_amount": 446,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Nufarm Australia Limited"
  },
  {
    "id": "HerbicidePesticide_152",
    "name": "Ultro 900 WG Herbicide",
    "active_name": "carbetamide",
    "active_amount": 900,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "ADAMA"
  },
  {
    "id": "HerbicidePesticide_153",
    "name": "Uniform",
    "active_name": "azoxystrobin metalaxyl-M",
    "active_amount": 446,
    "classification": "A",
    "type": "Fungicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_154",
    "name": "Uptake",
    "active_name": "",
    "active_amount": 822,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Dow Agrosciences"
  },
  {
    "id": "HerbicidePesticide_155",
    "name": "Velocity",
    "active_name": "bromoxynil pyrasulfotole mefenpyr-ditheyl",
    "active_amount": 637.9,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Bayer CropScience Pty Ltd"
  },
  {
    "id": "HerbicidePesticide_156",
    "name": "Verdict 520",
    "active_name": "haloxyfop",
    "active_amount": 520,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "Dow Agrosciences"
  },
  {
    "id": "HerbicidePesticide_157",
    "name": "Vibrance CST",
    "active_name": "azoxystrobin metalaxyl-M sedaxane fludioxonil",
    "active_amount": 197.5,
    "classification": "A",
    "type": "Fungicide",
    "manufacturer": "Syngenta Australia"
  },
  {
    "id": "HerbicidePesticide_158",
    "name": "Voraxor",
    "active_name": "saflufenacil trifludimoxazin",
    "active_amount": 375,
    "classification": "A",
    "type": "Herbicide",
    "manufacturer": "BASF Australia Ltd"
  },
  {
    "id": "HerbicidePesticide_159",
    "name": "Wetter TX",
    "active_name": "",
    "active_amount": 1040,
    "classification": "A",
    "type": "Adjuvant",
    "manufacturer": "Nufarm Australia Limited"
  }
]

export const combinedHerbicideAndHerbicidePesticideOptions = [
  ...herbicideOptions,
  ...herbicidePesticideOptions
]