import { Property, PropertyType, Report, ReportResults } from "../models/property_models";
import {
    ReductionPlan,
    ReductionPlanResultDTO,
    ReductionPlanInputDTO,
    PlantTreesDTO,
    AllowRevegetationDTO,
} from "../models/reduction_plans_models";
import { BaselineType, getCompletedReports, getLatestCompletedReport, getPercentageChange } from "./reports";
import { Initiative, InitiativeType } from "../models/reduction_plans_models";
import { EmissionDataOption } from "../components/graph/reduction_graph";


/**
 * Calculate the projected results for a given year, based on its reduction plans.
 */
export const calculateProjectedResultsForYear = (
    year: number,
    completedReports: Report[],
    reductionPlans: ReductionPlan[]
): ReportResults => {
    const yearReport = completedReports.find((r) => r.financialYear === year)
    if (yearReport && yearReport?.results) {
        return yearReport.results
    }

    const latestReport = getLatestCompletedReport(completedReports)

    const latestReportResults = latestReport?.results;
    const projectedResults: ReportResults = {
        totals: {
            total: latestReportResults?.totals.total ?? 0,
            sequestration: latestReportResults?.totals.sequestration ?? 0,
            net: latestReportResults?.totals.net ?? 0,
        },
        sequestration: {
            soil: latestReportResults?.sequestration.soil ?? 0,
            trees: latestReportResults?.sequestration.trees ?? 0,
        },
        byScopes: {
            scope1: latestReportResults?.byScopes.scope1 ?? 0,
            scope2: latestReportResults?.byScopes.scope2 ?? 0,
            scope3: latestReportResults?.byScopes.scope3 ?? 0,
        },
        scope1ByGas: {
            carbonDioxide: latestReportResults?.scope1ByGas.carbonDioxide ?? 0,
            methane: latestReportResults?.scope1ByGas.methane ?? 0,
            nitrousOxide: latestReportResults?.scope1ByGas.nitrousOxide ?? 0,
        },
        emissions: {
            sheepBeef: latestReportResults?.emissions.sheepBeef ?? 0,
            grains: latestReportResults?.emissions.grains ?? 0,
            shared: latestReportResults?.emissions.shared ?? 0,
        },
        intensity: {
            netPerDSE: latestReportResults?.intensity.netPerDSE ?? 0,
            kgCo2PerKgBeefMeatSold: latestReportResults?.intensity.kgCo2PerKgBeefMeatSold ?? 0,
            kgCo2PerKgGrainProduced: latestReportResults?.intensity.kgCo2PerKgGrainProduced ?? 0,
            kgCo2PerKgGreasyWool: latestReportResults?.intensity.kgCo2PerKgGreasyWool ?? 0,
            kgCo2PerKgSheepMeatSold: latestReportResults?.intensity.kgCo2PerKgSheepMeatSold ?? 0,
            netEmissionsBeef: latestReportResults?.intensity.netEmissionsBeef ?? 0,
            netEmissionsSheep: latestReportResults?.intensity.netEmissionsSheep ?? 0,
            netGrainsEmissions: latestReportResults?.intensity.netGrainsEmissions ?? 0,

            kgCo2PerKgMilkFPCM: latestReportResults?.intensity.kgCo2PerKgMilkFPCM ?? 0,
            kgCo2PerKgMilk: latestReportResults?.intensity.kgCo2PerKgMilk ?? 0,
            kgCo2PerKgDairyMeat: latestReportResults?.intensity.kgCo2PerKgDairyMeat ?? 0,

            kgCo2PerKgMeatFed: latestReportResults?.intensity.kgCo2PerKgMeatFed ?? 0,
            kgCo2PerKgMeatFedScope1and2: latestReportResults?.intensity.kgCo2PerKgMeatFedScope1and2 ?? 0,
            netFeedlotEmissions: latestReportResults?.intensity.netFeedlotEmissions ?? 0,
        },
    };

    if (latestReport === undefined) return projectedResults

    reductionPlans.forEach((plan) => {
        if (Array.isArray(plan.results)) {
            const inputs = plan.inputs as ReductionPlanInputDTO[];

            for (const input of inputs) {
                if ((input as PlantTreesDTO | AllowRevegetationDTO).startYear <= year) {
                    const currentIndex = inputs.indexOf(input);
                    const currentResults: any = plan.results[currentIndex];

                    for (const key in projectedResults) {
                        for (const subKey in projectedResults[
                            key as keyof ReductionPlanResultDTO
                        ]) {
                            (
                                projectedResults[
                                key as keyof ReductionPlanResultDTO
                                ] as any
                            )[subKey] +=
                                currentResults[key] !== undefined
                                    ? currentResults[key][subKey] ?? 0
                                    : 0;
                        }
                    }
                }
            }
        } else {
            const inputs = plan.inputs as ReductionPlanInputDTO;
            const results = plan.results as Record<string, ReportResults>;

            // For each component of the plan results.
            for (const component in plan.results) {
                const input = (inputs as any)[component];

                if (input.targetYear > latestReport.financialYear) {
                    const currentResults: any = results[component];

                    // For each component of the projected results.
                    for (const key in projectedResults) {
                        // For each subcomponent of the current result component.
                        for (const subKey in projectedResults[
                            key as keyof ReportResults
                        ]) {
                            // Exclude results of beefDSE and sheepDSE from intensity
                            // calculations.
                            if (
                                excludedIntensityParams.includes(component) &&
                                key === "intensity"
                            ) {
                                break;
                            }

                            // Tom's brain produced this cool function.
                            (
                                projectedResults[
                                key as keyof ReportResults
                                ] as any
                            )[subKey] +=
                                ((currentResults[key] !== undefined
                                    ? currentResults[key][subKey] ?? 0
                                    : 0) *
                                    Math.min(
                                        year - latestReport.financialYear,
                                        input.targetYear -
                                        latestReport.financialYear
                                    )) /
                                (input.targetYear - latestReport.financialYear);
                        }
                    }
                }
            }
        }
    });
    return projectedResults;
};

const excludedIntensityParams = ["beefDSE", "sheepDSE"];

/**
 * Calculate the projected results for some given years, based on their reduction plans.
 */
export const calculateResultsForYears = (
    reports: Report[],
    years: number[],
    reductionPlans: ReductionPlan[]
): Record<
    number,
    { results: ReductionPlanResultDTO; isProjection: boolean }
> => {
    const latestReport = getLatestCompletedReport(reports);
    const resultsRecord: Record<
        number,
        { results: ReductionPlanResultDTO; isProjection: boolean }
    > = {};
    for (const year of years) {
        const report = getCompletedReports(reports).find(
            (r: Report) => r.financialYear === year
        );

        const results: ReportResults | undefined = report?.results;

        // If report for this year has been completed.
        if (results) {
            resultsRecord[year] = { results: results, isProjection: false };
        }

        // If no completed report for this year exists
        else {
            // If this year is a projected year, use data from latest completed report
            // and deltas from reduction plans.
            if (latestReport && year > latestReport.financialYear) {
                const projectedResults = calculateProjectedResultsForYear(
                    year,
                    getCompletedReports(reports),
                    reductionPlans
                );
                resultsRecord[year] = {
                    results: projectedResults,
                    isProjection: true,
                };
            }
        }
    }
    return resultsRecord;
};

/**
 * Get the active reduction plans count for some given years.
 */
export const getActivePlansCount = (
    reductionPlans: ReductionPlan[],
    years: number[]
): Record<number, number> => {
    const counts: Record<number, number> = {};
    for (const year of years) {
        let yearCount = 0;

        reductionPlans.forEach((plan) => {
            if (plan.inputs instanceof Array) {
                const inputs = plan.inputs as ReductionPlanInputDTO[];
                for (const input of inputs) {
                    if ((input as PlantTreesDTO | AllowRevegetationDTO).startYear === year) {
                        // In this case, year count = count of inputs.
                        yearCount++;
                    }
                }
            } else {
                for (const key in plan.inputs) {
                    const input = (plan.inputs as any)[key];
                    if (input.targetYear >= year) {
                        yearCount++;

                        // In this case, year count = 1 regardless of inputs' count.
                        break;
                    }
                }
            }
        });
        counts[year] = yearCount;
    }
    return counts;
};

export function filterAvoidReductionInitiativesByLastCompleteReport (initiatives: Initiative[], lastReport: Report) {
    return initiatives.filter(i => {
        if (lastReport.algorithmVersion > 2 && i.id === InitiativeType.ManageLivestock) return false
        else if (lastReport.algorithmVersion < 3 && (i.id === InitiativeType.ManageLivestockClassesProduction || i.id === InitiativeType.ManageLivestockClassesProductionNZ)) return false

        console.log(lastReport)
        if (lastReport.algorithmVersion > 3.4 && i.id === InitiativeType.ManagePasture) return false
        else if (lastReport.algorithmVersion < 3.5 && i.id === InitiativeType.ManagePasture_Post_V3_4) return false

        return true
    })
}

export function getAvailableChartOptions (property: Property, callback: (data: EmissionDataOption) => void) {
    return [
        EmissionDataOption.EmissionsData,
        ...(property?.cattleFarm ? [EmissionDataOption.BeefIntensity] : []),
        ...(property?.sheepFarm ? [
            EmissionDataOption.SheepIntensity, EmissionDataOption.WoolIntensity
        ] : []),
        ...(property?.grainFarm ? [EmissionDataOption.GrainIntensity] : []),
        ...(property?.propertyType === PropertyType.Dairy ? [EmissionDataOption.DairyFPCM] : []),
        ...(property?.propertyType === PropertyType.Dairy ? [EmissionDataOption.DairyMilkSolids] : []),
        ...(property?.propertyType === PropertyType.Dairy ? [EmissionDataOption.DairyLiveWeight] : []),
        ...(property?.propertyType === PropertyType.Feedlot ? [EmissionDataOption.FeedlotIntensity, EmissionDataOption.FeedlotScope12Intensity] : []),
    ].map((option) => {
        return {
            label: option,
            onClick: () => callback(option),
        };
    });
}

export function calculatePercentageDifferenceFromBaseline (
    projectedResults: ReportResults,
    baseline: BaselineType,
    selectedMetric: EmissionDataOption
): number {
    return getPercentageChange(projectedResults, baseline, selectedMetric);
}