import { PropsWithChildren } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { useAuthStore } from "../state/auth_store";
import UserCountryGuard from "./UserCountryGuard";

/**
 * Redirects the user if the auth state doesn't match
 * @param children the react page component
 * @param blockAuthenticated whether the user has to be authenticated to view the page
 */
export default function AuthGuard({
    children,
    blockAuthenticated,
    allowUnverified,
}: PropsWithChildren<{
    blockAuthenticated?: boolean;
    allowUnverified?: boolean;
}>) {
    const authStore = useAuthStore();
    const location = useLocation();
    let [searchParams, _setSearchParams] = useSearchParams();

    let redirectLocation = searchParams.get("redirect")
    if (redirectLocation) {
        const redirectHostName = new URL(redirectLocation).hostname
        const existingHostname = window.location.hostname
        if (redirectLocation && redirectHostName !== existingHostname) {
            redirectLocation = null
        }
    }

    // Block user from accessing page while authenticated
    if (blockAuthenticated) {
        // This case is being triggered during the identity_actions redirection flow
        // when the login page re-renders after the user has been logged in
        if (redirectLocation && authStore.loggedIn) {
            // Don't seem to be able to use <Navigate> because that wants to create a relative URL
            window.location.assign(redirectLocation)
            return
        }

        return !authStore.loggedIn ? children : <Navigate to="/dashboard" />;
    }

    // This is a special condition where someone is logged in
    // but they haven't verified their email address yet
    if (
        authStore.loggedIn &&
        !authStore.user?.emailVerified &&
        !allowUnverified &&
        location.pathname !== "/identity_actions"
    ) {
        return <Navigate to="/checkVerification" />;
    }

    // Block user from accessing page while unauthenticated
    if (!authStore.loggedIn) {

        // Unless they are trying to reset their password
        // This is a fiddily case because we are required to one identity_actions route
        // that serves two purposes. Email Verification required someone to be logged in.
        // But password reset doest not
        if (location.pathname === "/identity_actions" && location.search.includes('resetPassword')) {
            return children
        }

        // If you were trying to go somewhere else then add it as a redirect URL
        let loginUrl = location.pathname === '/login' ? '/login' : `/login?redirect=${encodeURIComponent(window.origin + location.pathname + location.search)}`
        return <Navigate to={loginUrl} />;
    }
    return <UserCountryGuard>{children}</UserCountryGuard>
}