import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { BaseFormField, FormWrapper, InputWrapper, StyledInput, TextTip, TextTipWrapper } from "../styled_input";
import { ruminatiColors } from "../../utilities/colors";
import Tip from "../tip";
import { BodyText } from "../styled_text";
import { FieldSize, State, Validator } from "../../models/form/form_fields/form_field";
import { Column, Row } from "../styled_layout";

export type MultiInputFieldValues = { [key: string]: string | undefined }

interface MultiInputFieldProps {
    keys: string[];
    values: MultiInputFieldValues;
    placeholders: { [key: string]: string };
    unit?: string | { [key: string]: string };
    labelsAbove?: { [key: string]: string };
    labelsInside?: { [key: string]: string };
    onChange?: (value: MultiInputFieldValues, decimalPlaces?: number) => void;
    tips?: { [key: string]: string };
    error?: boolean;
    size?: "large" | "small";
    columnWidth?: FieldSize;
    singleValidator?: Validator<string>;
    state: State<any>;
    characterValidator?: (char: string, value: string) => boolean;
    showMissingFields?: boolean;
}

export function MultiInputField(props: MultiInputFieldProps) {
    const [values, setValues] = useState<MultiInputFieldValues>(props.values);

    useEffect(() => {
        setValues(props.values);
    }, [props.values]);

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, props.keys.length);
    }, [props.keys]);

    // Called to give focus to the DOM field element
    const focusField = (i: number) => {
        if (inputRefs.current && inputRefs.current.length > i) {
            const inputRef = inputRefs.current[i];
            if (inputRef != null) inputRef.focus();
        }
    };

    const validate = (key: string) => {
        const value = (values[key] ?? "").toString();
        return props.singleValidator ? props.singleValidator(value, props.state) : !props.error;
    };

    const handleChangeSingle = (key: string, newValue: string): string | undefined => {
        const value = values[key];

        if (newValue.length < (value?.length ?? 0)) {
            return newValue;
        }

        if (newValue.length > (value?.length ?? 0)) {
            if (props.characterValidator) {
                return props.characterValidator(newValue.charAt(newValue.length - 1), (value ?? "")) ? newValue : value;
            }
        }

        return newValue;
    }

    const handleChange = (key: string, value: string, decimalPlaces?: number) => {
        const newValue = handleChangeSingle(key, value);

        const newValues = {
            ...values,
            [key]: newValue,
        };
        setValues(newValues);
        if (props.onChange !== undefined)
            props.onChange(newValues, decimalPlaces);
    }

    const nonSeperatorKeys = props.keys.filter(k => !k.includes('seperator'))
    const colWidth = props.columnWidth || (nonSeperatorKeys.length > 2 ? FieldSize.Third : nonSeperatorKeys.length > 1 ? FieldSize.Half : FieldSize.Full)

    return (
        <Row style={{ 
            display: 'flex',
            justifyContent: 'flex-start',
            columnGap: FieldSize.getColumnGap(colWidth),
            flexFlow: 'wrap'
        }}>
            {
                props.keys.map((key, index) => {
                    let unit = '' 
                    let errorMessage = ''
                    if (typeof props.unit === 'string') unit = props.unit
                    else if (props.unit && props.unit[key]) unit = props.unit[key]

                    if (key.includes('seperator')) {
                        return (
                            <Column
                              style={{ flex: '1 1 100%', marginBottom: '8px' }}
                              key={key}
                            />
                        )
                    }

                    if (!validate(key)) {
                        if (!values[key] && props.showMissingFields) {
                            errorMessage = 'Required'
                        } else {
                            errorMessage = 'Invalid'
                        }
                    }

                    return <Column key={key}
                        style={{
                            width: FieldSize.toWidth(colWidth)
                        }}
                    >
                        {props.labelsAbove && <BodyText
                            style={{
                                width: "100%",
                                fontSize: "14px",
                                lineHeight: "24px",
                                fontWeight: 500,
                                marginTop: "2px",
                                marginBottom: "4px",
                                textAlign: "start",
                                letterSpacing: "0.28px",
                                color: ruminatiColors.light_green
                            }}
                        >
                            {props.labelsAbove[key]}
                        </BodyText>}
                        <BaseFormField
                            color={!validate(key) ? ruminatiColors.red : ruminatiColors.green_3}
                            style={{ cursor: "text" }}
                            onClick={() => focusField(index)}
                            width="100%"
                            isSmall={props.size === "small"}
                        >
                            <FormWrapper onClick={() => focusField(index)}>
                                <InputWrapper style={{
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    {/* INPUT FIELD */}
                                    {
                                        <StyledInput
                                            size={Math.min((values[key] ?? props.placeholders[key]?? "").toString().length, 20)}
                                            ref={el => inputRefs.current[index] = el}
                                            placeholder={props.placeholders[key]}
                                            type={"text"}
                                            disabled={false}
                                            color={ruminatiColors.green_3}
                                            spellCheck="false"
                                            value={(values[key] ?? "").toString()}
                                            style={{ fontSize: props.size === "small" ? "16px" : "20px" }}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                handleChange(key, v);
                                            }}
                                        />
                                    }
                                    {props.unit && 
                                        <InsideLabelText 
                                            style={{
                                                fontSize: props.size === "small" ? "14px" : "18px",
                                                flexGrow: 2
                                            }}>
                                            {unit}
                                        </InsideLabelText>
                                    }

                                    {/* TEXT FIELD LABEL */}
                                    <TextTipWrapper
                                        style={{
                                            // width: values[key] !== undefined ? "auto" : "0px",
                                            color: ruminatiColors.green_3_50
                                        }}
                                    >
                                        <TextTip>
                                            {errorMessage}{" "}
                                            {
                                                props.labelsInside ? 
                                                props.labelsInside[key]
                                                : undefined
                                            }
                                        </TextTip>
                                    </TextTipWrapper>
                                </InputWrapper>
                            </FormWrapper>

                            {/* TIP BUTTON */}
                            {props.tips &&  (
                                <Tip
                                    tip={props.tips[key] ?? ""}
                                />
                            )}
                        </BaseFormField>
                    </Column>
                })
            }
        </Row>
    );
}

const InsideLabelText = styled.p`
    font-weight: 400;
    font-family: "Approach", sans-serif;
    font-size: 20px;
    line-height: 32px;

    margin: 0;
    white-space: nowrap;
`;
