import { useState } from "react";

import { FormData } from "../../models/form/form";
import { DropdownFormField, NumberFormField } from "../../models/form/form_fields/basic_form_fields";
import { HerbicideOrPesticideUse } from "@/models/report";
import { combinedHerbicideAndHerbicidePesticideOptions } from "@/models/form_chemical_lookup";
import { FieldSize } from "@/models/form/form_fields/form_field";
import ArrayForm from "@/models/form/array_form";

const herbicidePesticideFormOptions = combinedHerbicideAndHerbicidePesticideOptions.map(fo => {
  return {
      "value": fo.id,
      "name": fo.name
  }
})

const calculatorForm = (): FormData<HerbicideOrPesticideUse> => ({
    fields: {
      id: new DropdownFormField({
        required: true,
        label: 'Herbicide/Pesticide Type',
        placeholder: "Herbicide/Pesticide Type",
        options: herbicidePesticideFormOptions,
        size: FieldSize.Half,
        useSuggestion: true
      }),
      rateApplied: new NumberFormField({
          required: true,
          label: "Rate applied",
          unit: "(L or kg)/ha",
          size: FieldSize.Quarter,
      }),
      areaAppliedHectares: new NumberFormField({
        required: true,
        label: "Area applied",
        unit: "ha",
        size: FieldSize.Quarter,
    })
    },
});
export const HerbicidePopupId = 'HerbicideUseId'

export default function HerbicideUsePopup({
    onSubmit,
    metadata
}: {
    onSubmit: (val: HerbicideOrPesticideUse[]) => void;
    metadata: {
        herbicidesPesticidesUsed: HerbicideOrPesticideUse[] | undefined
    }
}) {
    const formData = calculatorForm()

    const [formValues,] = useState<Partial<HerbicideOrPesticideUse>[]>(
        metadata.herbicidesPesticidesUsed && metadata.herbicidesPesticidesUsed.length > 0 ? metadata.herbicidesPesticidesUsed : [{}]
    );

    return (
        <div style={{ padding: "20px", width: '750px' }}>
            <ArrayForm
                data={formData as FormData<any, any>}
                initialValue={formValues}
                onChange={async () => undefined}
                onSubmit={async (values) => {
                    onSubmit(values as HerbicideOrPesticideUse[])
                }}
                sectionName={"Herbicide / Pesticide"}
                hideSubmitButton={false}
                useRowStyle={true}
                submitText="Submit"
            />
        </div>
    );
}
