import { useNavigate, useParams } from "react-router-dom";

import Heading from "../../components/heading";
import { Row } from "../../components/styled_layout";
import { CreateReportContainer } from "../wrapper/farm_wrapper";
import { InfoCard } from "../../components/card";
import { BodyText, ErrorText, ParagraphText, TextLink } from "../../components/styled_text";
import SummarySection from "../../components/summary_section";
import OutlineButton from "../../components/buttons/outline_button";
import { Report, Property } from "../../models/property_models";
import MainButton from "../../components/buttons/main_button";
import { ruminatiColors } from "../../utilities/colors";
import { useDatabaseStore } from "@/state/database_store";
import { getPropertyFromReport, getReport } from "@/utilities/functions";
import { generatePages } from "./form_screen";
import Screen from "../screen";
import PropertyHeader from "@/components/headers/property_header";
import FormSteps from "./form_steps";
import { useAuthStore } from "@/state/auth_store";
import { HelpDrawerAndTab, HelpDrawerContentWrapper, HelpDrawerExplanatoryContentContainer, HelpDrawerExplanatoryContentText, HelpDrawerExplanatoryContentTitle, HelpDrawerVideoContainer } from "@/components/HelpDrawer";
import { Accordion, AccordionItem } from "@/components/Accordion";

/**
 * A screen showing the summary of each form page and its data
 * @returns the Summary Screen component
 */
export default function SummaryScreen() {
    const navigate = useNavigate()
    const { reportId } = useParams();
    const databaseStore = useDatabaseStore();
    const user = useAuthStore((state) => state.user);

    const report: Report | undefined = getReport(
        databaseStore.reports,
        reportId
    );

    const property: Property | undefined = getPropertyFromReport(
        databaseStore.properties,
        report
    );
    if (!property || !report || !user) return undefined

    const pages = generatePages({
        property, 
        goToForm: (_formId: string) => undefined, 
        report,
        useSeasons: true
    })

    return (
        <Screen pageTitle={"Report - Summary"}>
            <PropertyHeader property={property} />
            <CreateReportContainer 
            style={{ 
                width: 'calc(100vw - 470px)',
                maxWidth: '700px',
            }}>
                <FormSteps
                    pages={pages}
                    pageId="summary"
                    report={report}
                    user={user}
                    navigate={(targetStepId)=> {
                        if (targetStepId === 'summary') return
                        navigate(`/form/${reportId}/${targetStepId}`)
                    }}
                    paddingTop="20px"
                />
                <Heading level={2}>Report Input Summary</Heading>

                <ParagraphText style={{ margin: "24px 0px" }}>
                    {`${property.name} - ${report.financialYear} Financial Year`}
                </ParagraphText>

                <Row style={{marginBottom: '24px'}}>
                    <InfoCard 
                        padding="24px"
                        content={
                            <BodyText style={{lineHeight: '24px'}}>The information reported here is not shared to any third-party's until you opt-in.
                            To manage what you are sharing, and with who, visit the <TextLink 
                                style={{color: ruminatiColors.dark_green}}
                                onClick={() => {
                                    navigate('/partners')
                                }}
                            >Corporate Partners</TextLink> section</BodyText>
                        } />
                </Row>
                
                <Row style={{marginBottom: '24px', flexWrap: 'wrap'}}>
                {
                    report.complete === true ? 
                    <>
                            <MainButton
                                colorScheme="green"
                                size="medium"
                                onClick={() => {
                                    navigate(`/report/${report.id}`)
                                }}
                            >
                                View Report Results
                            </MainButton>
                        </>
                    :
                    <>
                            <ErrorText style={{ marginBottom: "16px" }}>
                                Please provide the outstanding information below to generate your emissions report.
                            </ErrorText>                        
                            <OutlineButton disabled width="200px" height="40px">
                                View Report
                            </OutlineButton>
                    </>
                }
                </Row>
                
                <Row style={{flexWrap: 'wrap'}}>
                    {pages.filter(p => p.id !== 'summary').map((page) => {
                        if ('formComponents' in page) {
                            page.formComponents.map(fc => {
                                return (
                                    <SummarySection key={fc.id} page={fc} report={report} />
                                );
                            })
                        } else {
                            return (
                                <SummarySection key={page.id} page={page} report={report} />
                            );
                        }
                    })}
                </Row>

                {report.complete === true && (
                    <Row style={{marginBottom: '24px', marginTop: '8px',  flexWrap: 'wrap'}}>
                        <MainButton
                            colorScheme="green"
                            size="medium"
                            onClick={() => {
                                navigate(`/report/${report.id}`)
                            }}
                        >
                            View Report Results
                        </MainButton>
                    </Row>
                )}
            </CreateReportContainer>
            <HelpDrawerAndTab
                renderContent={() => {
                    return (
                        <HelpDrawerContentWrapper>
                            <HelpDrawerExplanatoryContentContainer>
                                <HelpDrawerExplanatoryContentTitle>Review Your Data</HelpDrawerExplanatoryContentTitle>
                                <HelpDrawerExplanatoryContentText>
                                    Review all your data inputs on this page. If you need to edit anything, click the ‘edit information’ button and it will take you back to the relevant section.
                                </HelpDrawerExplanatoryContentText>
                            </HelpDrawerExplanatoryContentContainer>
                            <HelpDrawerVideoContainer>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/H6aSR-AZZR4?si=xAmpakHqFb2JqH7I&loop=1&rel=0" title="Ruminati Help" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </HelpDrawerVideoContainer>
                            <Accordion>
                                <AccordionItem
                                    renderTitle="How do I change my inputs if I’ve made a mistake?"
                                    renderContent="Click on the ‘Edit Information’ button next to each section on this summary page to go back and edit your inputs. Don’t worry if you notice something later, you can do this even after you’ve produced an initial report."
                                />
                            </Accordion>
                        </HelpDrawerContentWrapper>
                    )
                }}
            />
    </Screen>
    );
}
