import { TextField } from "../../../components/form/input_field";
import { IconType } from "../../../components/icon";
import { BaseText } from "../../../components/styled_text";
import { ruminatiColors } from "../../../utilities/colors";

export interface FieldElement<T> {
    required: (state: any) => boolean;
    validate: Validator<T>;
}

export enum NumberType {
    Float,
    Int,
}

export enum FieldSize {
    Twelth,
    Sixth,
    Fifth,
    Quarter,
    Third,
    Half,
    TwoThirds,
    Full,
}

export enum ConcatMethod {
    Average,
    Total,
    StringList,
    Boolean,
}

export namespace FieldSize {
    export function toWidth(size: FieldSize): string {
        switch (size) {
            case FieldSize.Twelth:
                return "8%"
            case FieldSize.Sixth:
                return "16.6%"
          case FieldSize.Fifth:
                return "19%"
            case FieldSize.Quarter:
                return "24%"
            case FieldSize.Third:
                return "33%"
            case FieldSize.TwoThirds:
                return "66%"
            case FieldSize.Half:
                return "49%"
            case FieldSize.Full:
                return "100%"
        }
    }

    export function getColumnGap(size: FieldSize): string {
        switch (size) {
            case FieldSize.Sixth:
                return "0.6%"
            case FieldSize.Fifth:
                return "0.8%"
            case FieldSize.Quarter:
                return "0.8%"
            case FieldSize.Third:
                return "0.3%"
            case FieldSize.Half:
                return "1%"
            case FieldSize.Full:
                return "0%"
        }
        return "0%"
    }
}

export type DropdownOptionData<T> = {
    icon?: IconType;
    value: T;
    name: string;
};

export type Validator<T> = (value: T | undefined, state: State<any>) => boolean;
export type OnChangeFunction<T> = (value?: T) => any;
export type TypeOrFunctionOfState<T> = T | ((state: State<any>) => T);
export type State<T> = {
    get: T;
    set: (state: T) => void;
    index: number;
    getAll?: T[];
};

export function evaluate<T>(value: TypeOrFunctionOfState<T>, state: State<any>): T {
    return value instanceof Function ? value(state) : value;
}

export interface FormField<T> extends FieldElement<T> {
    displayValue: (value?: T) => string | undefined;
    size: FieldSize;
    label?: TypeOrFunctionOfState<string>;
    render: (
        id: string,
        onChange: (value?: T) => void,
        state: State<any>,
        value?: T,
        error?: boolean,
        size?: "large" | "small",
        label?: TypeOrFunctionOfState<string>,
        showMissingFields?: boolean
    ) => JSX.Element;
}

export interface InputFormFieldArgs<T> {
    required?: TypeOrFunctionOfState<boolean>;
    placeholder?: TypeOrFunctionOfState<string>;
    tip?: string;
    size?: FieldSize;
    validator?: Validator<T>;
    obscured?: boolean;
    children?: TypeOrFunctionOfState<JSX.Element>;
    concatMethod?: ConcatMethod;
    label?: TypeOrFunctionOfState<string>;
    summaryLabel?: TypeOrFunctionOfState<string>;
    unit?: TypeOrFunctionOfState<string>;
    disabled?: boolean;
    hide?: TypeOrFunctionOfState<boolean>;
}

export class InputFormField<T> implements FormField<T> {
    size: FieldSize;
    _placeholder: TypeOrFunctionOfState<string>;
    _required: TypeOrFunctionOfState<boolean>;
    _concatMethod?: ConcatMethod;
    tip?: string;
    validator?: Validator<T>;
    obscured: boolean;
    _children: TypeOrFunctionOfState<JSX.Element | undefined>;
    _label: TypeOrFunctionOfState<string>;
    _summaryLabel: TypeOrFunctionOfState<string>
    _unit: TypeOrFunctionOfState<string>
    _disabled?: boolean = false;
    _hide: TypeOrFunctionOfState<boolean> = false

    constructor(args: InputFormFieldArgs<T>) {
        this.size = args.size ?? FieldSize.Full;
        this._placeholder = args.placeholder ?? "";
        this.tip = args.tip;
        this._required = args.required ?? false;
        this.validator = args.validator;
        this.obscured = args.obscured ?? false;
        this._children = args.children;
        this._concatMethod = args.concatMethod;
        this._label = args.label ?? "";
        this._summaryLabel = args.summaryLabel ?? this._placeholder;
        this._unit = args.unit ?? "";
        this._disabled = args.disabled ?? false;
        this._hide = args.hide ?? false
    }
    displayValue = (value?: T) => value?.toString();

    hide(state: State<any>): boolean {
        return evaluate(this._hide, state);
    }

    required(state: State<any>): boolean {
        return evaluate(this._required, state);
    }

    unit(state: State<any>): string {
        return `${evaluate(this._unit, state)}`;
    }

    placeholder(state: State<any>): string {
        return evaluate(this._placeholder, state);
    }

    units(state: State<any>): string {
        return evaluate(this._unit, state);
    }

    label(state: State<any>) :string {
        return evaluate(this._label, state)
    }

    summaryLabel(state: State<any>): string {
        return this._summaryLabel ? `${evaluate(this._summaryLabel, state)}` : this.label(state);
    }

    children(state: State<any>): JSX.Element | undefined {
        let child;
        if (typeof this._children === "function") {
            child = evaluate(this._children, state);
        } else {
            child = this._children;
        }
        // If not required, add optional tag
        if (!evaluate(this._required, state)) {
            return (
                <>
                    {child}
                    <BaseText
                        style={{
                            color: ruminatiColors.green_3_30,
                            fontSize: "14px",
                            userSelect: "none",
                            margin: "0 0 0 8px",
                        }}
                    >
                        optional
                    </BaseText>
                </>
            );
        }

        return child;
    }

    validate(value: T | undefined, state: State<any>): boolean {
        return this.validator
            ? this.validator(value, state)
            : value !== undefined;
    }

    transform(_value?: string): T | undefined {
        throw Error("Unimplemented");
    }

    render(
        id: string,
        onChange: (value?: T) => void,
        state: State<any>,
        value?: T,
        error?: boolean,
        size?: "large" | "small",
        _label?: TypeOrFunctionOfState<string>,
        _showMissingFields?: boolean
    ): JSX.Element {
        if (this.hide(state)) {
            return <></>
        }
        return (
            <TextField
                id={id}
                value={this.displayValue(value)}
                error={error}
                onChange={(value) => onChange(this.transform(value))}
                label={this.label(state)}
                placeholder={this.placeholder(state)}
                tip={this.tip}
                width={FieldSize.toWidth(this.size)}
                size={size}
                obscure={this.obscured}
                footerElement={this.children(state)}
                disabled={this._disabled}
            />
        );
    }
}
