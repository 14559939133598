import { uid } from "uid";

import EmptyCTA from "../../../components/empty_cta";
import { TextFormField, DropdownFormField, NumberFormField } from "../../../models/form/form_fields/basic_form_fields";
import { CustomField } from "../../../models/form/form_fields/decoration_form_fields";
import { ConcatMethod, FieldSize, NumberType, Validator } from "../../../models/form/form_fields/form_field";
import { FeedProportion, ReportFeedlotCohorts, ReportFeedlotProduction, ReportFormRations, ReportRations } from "../../../models/report";
import { CollapsableFieldWrapper } from "../../../models/form/form_fields/form_field_wrappers";
import HiddenFormField from "../../../models/form/form_fields/hidden_form_field";
import { MultiInputFormField } from "../../../models/form/form_fields/multi_input_form_field";
import { PercentageSplitFormField } from "../../../models/form/form_fields/percentage_split_form_field";
import { ReportFormPageId, TransformType, applyNumberTransformerToObject, inputPercent, outputPercent, outputSplitPercent } from "../../../utilities/forms";
import { Report } from "../../../models/property_models";
import { FormData } from "../../../models/form/form";
import { FormPageRepeatableConfig, ReportFormPage } from "../../../models/form/form_page";

const feedProportionLabels = [
  { key: "grains", label: "Grains (inc molasses)" },
  { key: "concentrates", label: "Other Concentrates" },
  { key: "grasses", label: "Grasses" },
  { key: "legumes", label: "Legumes" },
];

const defaultFeedProportions: Record<string, FeedProportion> = {
    cellulose: {
        grains: 0.07,
        concentrates: 0.19,
        grasses: 0.31,
        legumes: 0.36
    },
    hemicellulose: {
        grains: 0.04,
        concentrates: 0.11,
        grasses: 0.31,
        legumes: 0.2
    },
    solubleResidue: {
        grains: 0.68,
        concentrates: 0.19,
        grasses: 0.21,
        legumes: 0.21
    },
    nitrogen:  {
        grains: 0.02,
        concentrates: 0.05,
        grasses: 0.026,
        legumes: 0.032
    }
}

const compositionValidator: Validator<FeedProportion> = (value) => {
  if (value === undefined) return false;

  for (const label of feedProportionLabels) {
      const cellValue = value[label.key];
      if (cellValue === undefined) return false;
  }

  return true;
}

export const rations: ReportFormPage<ReportFormRations, ReportRations> = {
  id: ReportFormPageId.Rations,
  title: "Rations",
  repeatableConfig: {
      repeatableSectionName: "Ration",
      minimumRequired: 1,
      repeatableSectionCallback: (ration: ReportFormRations) => {
          if (ration.name === undefined) return 'Ration'
          return `Ration - ${ration.name}`
      }
  },
  image: "rations.png",
  formData: {
      fields: {
          id: new HiddenFormField(),
          name: new TextFormField({
              required: true,
              label: "Ration Group Name",
              concatMethod: ConcatMethod.StringList,
              tip: "Enter the name of the ration, for example “Starter Ration”",
          }),
          dryMatterDigestibility: new NumberFormField({
              required: true,
              label: "Dry matter digestibility",
              unit: "%",
              concatMethod: ConcatMethod.Average,
              size: FieldSize.Half,
              tip: "Enter the dry matter digestibility percentage of the feed eaten by the cattle on this ration (minimum 25%).",
              validator: (value) => value !== undefined && value <= 100 && value >= 25,
          }),
          crudeProtein: new NumberFormField({
              required: true,
              label: "Crude Protein",
              unit: "%",
              concatMethod: ConcatMethod.Average,
              size: FieldSize.Half,
              tip: "Enter the crude protein percentage of the ration as fed (minimum 2%).",
              validator: (value) => value !== undefined && value <= 100 && value >= 2,
          }),
          dietaryNetEnergyConcentration: new NumberFormField({
              required: true,
              label: "Dietary Net Energy Concentration",
              unit: "MJ/kg",
              concatMethod: ConcatMethod.Average,
              size: FieldSize.Half,
              tip: "Enter the net energy concentration of the ration as fed (between 2 and 30\u00a0Mj/kg).",
              validator: (value) => value !== undefined && value <= 30 && value >= 2,
          }),
          nitrogenRetention: new NumberFormField({
              required: true,
              label: "Nitrogen retention",
              unit: "% of intake",
              concatMethod: ConcatMethod.Average,
              size: FieldSize.Half,
              tip: "Enter the percentage of nitrogen intake retained.",
              validator: (value) => value !== undefined && value <= 100 && value >= 0,
          }),
          proportionOfFeed: new CollapsableFieldWrapper({
              label: "Proportion of Feed",
              data: {
                  fields: {
                      proportions: new PercentageSplitFormField({
                          options: feedProportionLabels,
                          tip: "Enter the percentage of the ration made up of grains, concentrates, grasses and legumes. This must equal 100.",
                          concatMethod: ConcatMethod.Average,
                          units: '',
                          columnWidth: FieldSize.Quarter
                      }),
                  }
              }
          }),
          compositionOfFeed: new CollapsableFieldWrapper({
              label: "Composition of Feed (Advanced)",
              startCollapsed: true,
              data: {
                  fields: {
                      cellulose: new MultiInputFormField({
                          title: "Cellulose",
                          options: feedProportionLabels,
                          useLabelsAbove: true,
                          allUnits: "%",
                          tip: "Enter the composition of each of the feed components, we have set some default values for you to use if you are unsure.",
                          validator: compositionValidator,
                          required: true,
                          summaryLabel: "Cellulose: ",
                          concatMethod: ConcatMethod.Average,
                          columnWidth: FieldSize.Quarter
                      }),
                      hemicellulose: new MultiInputFormField({
                          title: "Hemicellulose",
                          options: feedProportionLabels,
                          useLabelsAbove: true,
                          allUnits: "%",
                          tip: "Enter the composition of each of the feed components, we have set some default values for you to use if you are unsure.",
                          validator: compositionValidator,
                          required: true,
                          summaryLabel: "Hemicellulose: ",
                          concatMethod: ConcatMethod.Average,
                          columnWidth: FieldSize.Quarter
                      }),
                      solubleResidue: new MultiInputFormField({
                          title: "Soluble Residue",
                          options: feedProportionLabels,
                          useLabelsAbove: true,
                          allUnits: "%",
                          tip: "Enter the composition of each of the feed components, we have set some default values for you to use if you are unsure.",
                          validator: compositionValidator,
                          required: true,
                          summaryLabel: "Soluble Residue: ",
                          concatMethod: ConcatMethod.Average,
                          columnWidth: FieldSize.Quarter
                      }),
                      nitrogen: new MultiInputFormField({
                          title: "Nitrogen",
                          options: feedProportionLabels,
                          useLabelsAbove: true,
                          allUnits: "%",
                          tip: "Enter the composition of each of the feed components, we have set some default values for you to use if you are unsure.",
                          validator: compositionValidator,
                          required: true,
                          summaryLabel: "Nitrogen: ",
                          concatMethod: ConcatMethod.Average,
                          columnWidth: FieldSize.Quarter
                      }),
                  }
              }
          }),
      },
      transformer: {
          in: (value) => ({
              id: value.id,
              name: value.name,
              dryMatterDigestibility: inputPercent(value.dryMatterDigestibility),
              crudeProtein: inputPercent(value.crudeProtein),
              dietaryNetEnergyConcentration: value.dietaryNetEnergyConcentration,
              nitrogenRetention: inputPercent(value.nitrogenRetention),
              proportionOfFeed: {
                  proportions: {
                      grains: inputPercent(value.feedComponents?.proportions?.grains),
                      concentrates: inputPercent(value.feedComponents?.proportions?.concentrates),
                      grasses: inputPercent(value.feedComponents?.proportions?.grasses),
                      legumes: inputPercent(value.feedComponents?.proportions?.legumes),
                  },
              },
              compositionOfFeed: {
                  cellulose: applyNumberTransformerToObject(value.feedComponents?.cellulose ?? defaultFeedProportions.cellulose, TransformType.InputPercent) as FeedProportion,
                  hemicellulose: applyNumberTransformerToObject(value.feedComponents?.hemicellulose ?? defaultFeedProportions.hemicellulose, TransformType.InputPercent) as FeedProportion,
                  nitrogen: applyNumberTransformerToObject(value.feedComponents?.nitrogen ?? defaultFeedProportions.nitrogen, TransformType.InputPercent) as FeedProportion,
                  solubleResidue: applyNumberTransformerToObject(value.feedComponents?.solubleResidue ?? defaultFeedProportions.solubleResidue, TransformType.InputPercent) as FeedProportion,
              },
          }),
          out: (value) => ({
              id: value.id ?? uid(),
              name: value.name,
              dryMatterDigestibility: outputPercent(value.dryMatterDigestibility),
              crudeProtein: outputPercent(value.crudeProtein),
              dietaryNetEnergyConcentration: value.dietaryNetEnergyConcentration,
              nitrogenRetention: outputPercent(value.nitrogenRetention),
              feedComponents: {
                  proportions: {
                      grains: outputSplitPercent(value.proportionOfFeed?.proportions, feedProportionLabels.length, 'grains'),
                      concentrates: outputSplitPercent(value.proportionOfFeed?.proportions, feedProportionLabels.length, 'concentrates'),
                      grasses: outputSplitPercent(value.proportionOfFeed?.proportions, feedProportionLabels.length, 'grasses'),
                      legumes: outputSplitPercent(value.proportionOfFeed?.proportions, feedProportionLabels.length, 'legumes'),
                  },
                  cellulose: applyNumberTransformerToObject(value.compositionOfFeed?.cellulose, TransformType.OutputPercent) as FeedProportion,
                  hemicellulose: applyNumberTransformerToObject(value.compositionOfFeed?.hemicellulose, TransformType.OutputPercent) as FeedProportion,
                  nitrogen: applyNumberTransformerToObject(value.compositionOfFeed?.nitrogen, TransformType.OutputPercent) as FeedProportion,
                  solubleResidue: applyNumberTransformerToObject(value.compositionOfFeed?.solubleResidue, TransformType.OutputPercent) as FeedProportion,
              }
          }),
      },
  },
};

export const feedlotCohorts = (report: Report | undefined, goToRationGroups: () => void): ReportFormPage<ReportFeedlotCohorts | { empty: null }, ReportFeedlotCohorts> => {
  const rationGroups = report?.rationsInformation;
  const rationGroupOptions = rationGroups ? rationGroups.map(g => ({ value: g.id, name: g.name })) : [];

  let repeatableConfig: FormPageRepeatableConfig | undefined;
  let formData: FormData<ReportFeedlotCohorts | {
      empty: null;
  }, ReportFeedlotCohorts>;
  let hideActionButtons = false;

  if (rationGroupOptions.length === 0) {
      formData = {
          fields: {
              empty: new CustomField({
                  blockSubmission: true,
                  content: <div style={{ padding: "0 10%" }}>
                      <EmptyCTA text="You need to add a Ration Group before you can add a Feedlot Cohort"
                          buttonText="Add Ration Groups"
                          onClick={goToRationGroups} />
                  </div>,
              }),
          }
      };
      hideActionButtons = true;
  } else {
      formData = {
          fields: {
              name: new TextFormField({
                  required: true,
                  label: "Cohort name",
                  concatMethod: ConcatMethod.StringList,
                  tip: "Enter the name to help identify the cohort, for example “Domestic”",
              }),
              ration: new DropdownFormField<string>({
                  required: true,
                  concatMethod: ConcatMethod.StringList,
                  label: "Assign ration group",
                  size: FieldSize.Half,
                  options: rationGroupOptions,
              }),
              livestockNumbers: new NumberFormField({
                  required: true,
                  label: "Livestock Numbers",
                  type: NumberType.Int,
                  unit: "head",
                  concatMethod: ConcatMethod.Average,
                  size: FieldSize.Half,
                  // validator: (value, state) => value !== undefined && Number.isInteger(value),
                  tip: "Enter the number of cattle fed in this cohort",
              }),
              averageStay: new NumberFormField({
                  required: true,
                  label: "Avg length of stay on Ration",
                  unit: "days",
                  concatMethod: ConcatMethod.Average,
                  size: FieldSize.Half,
                  validator: (value) => value !== undefined && Number.isInteger(value),
                  tip: "Enter the average number of days the cattle were fed this ration.",
              }),
              liveweight: new NumberFormField({
                  required: true,
                  label: "Liveweight",
                  unit: "kg/head",
                  concatMethod: ConcatMethod.Average,
                  size: FieldSize.Half,
                  validator: (value) => value !== undefined && value <= 1500,
                  tip: "Enter the average liveweight of the cattle when fed this ration.",
              }),
          },
      };
      repeatableConfig = {
          repeatableSectionName: "Cohort",
          repeatableSectionCallback: (formData: ReportFeedlotCohorts) => {
              if (formData.name) return `Cohort - ${formData.name}`
              return 'Cohort'
          },
          minimumRequired: 1
      };
  }

  return {
      id: ReportFormPageId.FeedlotCohorts,
      title: "Feedlot Cohorts",
      description: "",
      image: "feedlot-cohorts.png",
      repeatableConfig,
      formData,
      hideActionButtons,
  };
};

export const feedlotProduction: ReportFormPage<ReportFeedlotProduction> = {
    id: ReportFormPageId.FeedlotProduction,
    image: "enterprise-feedlot.png",
    title: "Production",
    formData: {
        fields: {
            headSold: new NumberFormField({
                required: true,
                label: "Number of Head sold",
                size: FieldSize.Half
            }),
            avgLiveweight: new NumberFormField({
                required: true,
                label: "Average sale weight",
                size: FieldSize.Half,
                unit: "kg lwt/head",
                validator: (value) => value !== undefined && value <= 1500,
            })
        }
    }
}
